import React, { useState, Fragment, useEffect, useRef } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser, setTitle } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DateComponent from "../components/date";
import ComboBox from "../components/combobox";
import useViewport from "../utils/useViewport";
import toast from "react-hot-toast";
import { numberWithCommas, formatDate, endpoint } from "../utils/constants";
import door from "../assets/img/door.png";
import broom from "../assets/img/broom.png";
import floppy from "../assets/img/floppy.png";
import DESC from "../assets/img/DESC.png";
import product from "../assets/img/product.png";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import PaymentAlert from "../components/paymentAlert";
const SalesCreate = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [action, setAction] = useState("create");
  const [barcodeSearch, setBarcodeSearch] = useState("");
  const duesRef = useRef();
  const duesRefB = useRef();
  const { width } = useViewport();
  const { page } = useParams();
  const user = useSelector((state) => state.main.user);
  const [clients, setClients] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [client, setClient] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [saleConditions, setSaleConditions] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [filteredPaymentMethods, setFilteredPaymentMethods] = useState([]);
  const [filteredPaymentMethodsCompleted, setFilteredPaymentMethodsCompleted] = useState(false);
  const [open, setOpen] = useState(false);
  const [checksOpen, setChecksOpen] = useState(false);
  const [itemsOpen, setItemsOpen] = useState(false);
  const [opendes, setOpendes] = useState(false);
  const [willFocus, setWillFocus] = useState(false);
  const [willFocusB, setWillFocusB] = useState(false);
  const [sales, setSales] = useState([]);
  const [items, setItems] = useState([]);
  const [articles, setArticles] = useState([]);
  const [searchArticles, setSearchArticles] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [number, setNumber] = useState(user.box.number);
  const [expeditionPoint, setExpeditionPoint] = useState(
    user.box.expeditionPoint
  );
  const [stamped, setStamped] = useState(user.box.stamped);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountChange, setTotalAmountChange] = useState(0);
  const [totalAmountSin, setTotalAmountSin] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState("PYG");
  const [receivedValues, setReceivedValues] = useState([]);
  const [restReceivedValues, setRestReceivedValues] = useState([]);
  const [checks, setChecks] = useState([
    {
      bankId: 0,
      name: "",
      issueDate: "",
      expirationDate: "",
      amount: 0,
      currencyId: 0,
    },
  ]);
  const [dues, setDues] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [banks, setBanks] = useState([]);
  const [account, setAccount] = useState([]);
  const [priceId, setPriceId] = useState(0);
  const [itemsQty, setItemsQty] = useState(0);
  const [accountId, setAccountId] = useState(0);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [saleId, setSaleId] = useState(0);
  const [clientId, setClientId] = useState(0);
  const [depositId, setDepositId] = useState(0);
  const [currencyId, setCurrencyId] = useState(1);
  const [sellerId, setSellerId] = useState(user.id);
  const [companyId, setCompanyId] = useState(user.companyId);
  const [branchId, setBranchId] = useState(user.branchId);
  const [boxId, setBoxId] = useState(user.boxId);
  const [saleConditionId, setSaleConditionId] = useState(1);
  const [paymentMethodId, setPaymentMethodId] = useState(0);
  const barcodeInputRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [boole, setBoole] = useState(0);
  const [totalPages, setTotalPage] = useState(1);
  const [itera, setItera] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [valueDesc, setValueDesc] = useState(0);
  const [valuePorcentaje, setValueDescPor] = useState(0);
  const [itemDesc, setItemDesc] = useState([]);
  const [empresaId, setEmpresaId] = useState(user.companyId);
  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const data = {
    totalAmount: totalAmount,
    rates: [
      {
        id: 1,
        amount: 1,
        name: "PYG",
      },
    ],
  };

  const [exchangeData, setExchangeData] = useState(data);
  const [defaultCurrency, setDefaultCurrency] = useState(1);
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const [date, setDate] = useState(function () {
    let newDate = new Date().toLocaleDateString();
    return newDate
      .split("/")
      .reverse()
      .map((d) => {
        if (d.length == 1) {
          return "0" + d;
        } else {
          return d;
        }
      })
      .join("-");
  });

  const [deleted, setDeleted] = useState(0);
  const [rates] = useState({
    dolares: 7480, // 1 USD = 7000 Guaraníes (Ejemplo de tasa)
    pesos: 6, // 1 USD = 1750 Pesos (Ejemplo de tasa)
    reales: 1400, // 1 USD = 1250 Reales (Ejemplo de tasa)
    guaranies: 1000,
  });

  const [values, setValues] = useState({
    dolares: "",
    pesos: "",
    reales: "",
    guaranies: "",
  });
  const handleChange = (e) => {
    const { id, value } = e.target;
    const numericValue = value;

    // Realizar el cálculo correspondiente según el input
    let convertedValue = 0;

    switch (id) {
      case "dolares":
        convertedValue = numericValue * rates.dolares;
        setValues((prevState) => ({
          ...prevState,
          dolares: value,
          calcDolar: convertedValue,
        }));
        break;
      case "pesos":
        convertedValue = numericValue * rates.pesos;
        setValues((prevState) => ({
          ...prevState,
          pesos: value,
          calcPesos: convertedValue,
        }));
        break;
      case "reales":
        convertedValue = numericValue * rates.reales;
        setValues((prevState) => ({
          ...prevState,
          reales: value,
          calcReales: convertedValue,
        }));
        break;
      case "guaranies":
        convertedValue = numericValue * rates.guaranies;
        setValues((prevState) => ({
          ...prevState,
          reales: value,
          calcReales: convertedValue,
        }));
        break;
      default:
        break;
    }
  };
  async function fetchSales() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching((prev) => true);

    let salesRequest = await axiosAuth.get(
      "/salesup?page=" +
      pageQuery +
      "&companyId=" +
      user.companyId +
      "&branchId=" +
      user.branchId +
      "&limit=" +
      1
    );

    if (salesRequest.data.error) {
      if (salesRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    // salesRequest.data.sales.clients.unshift({
    //   id: 0,
    //   name: "Elija un cliente",
    // });

    setClients((prev) => salesRequest.data.clients.rows);
    setCurrencies((prev) => salesRequest.data.currencies);
    setBanks((prev) => salesRequest.data.banks);
    setAccounts((prev) => salesRequest.data.accounts);
    setArticles((prev) => salesRequest.data.items.rows);

    setSaleConditions((prev) => salesRequest.data.saleconditions);
    setPaymentMethods((prev) => salesRequest.data.paymentmethods);
    setDeposits((prev) => salesRequest.data.deposits);

    if (salesRequest.data.deposits.length > 0) {
      setDepositId((prev) => salesRequest.data.deposits[0].id);
    }

    let newFilteredPaymentMethods = [];

    salesRequest.data.paymentmethods.map((paymentMethod) => {
      if (paymentMethod.saleConditionId == saleConditionId) {
        paymentMethod.amount = null;
        newFilteredPaymentMethods.push(paymentMethod);
      }
    });

    setFilteredPaymentMethods((prev) => newFilteredPaymentMethods);

    let currentBox = salesRequest.data.boxes.filter(
      (box) => box.id == user.box.id
    )[0];

    setNumber((prev) => currentBox.number + 1);

    if (state != null) {
      let clientExists = false;

      salesRequest.data.clients.rows.map((clientRow) => {
        if (clientRow.id == state.clientId) {
          clientExists = true;
          setClient((prev) => clientRow);
          setClientId((prev) => state.clientId);
        }
      });

      if (!clientExists) {
        const getClientByIdRequest = await axiosAuth.get(
          "/client?id=" + state.clientId
        );

        let newClients = [...clients];
        newClients.push(getClientByIdRequest.data.client);
        setClientId((prev) => state.clientId);
        setPriceId((prev) => state.priceId);
        setClient((prev) => getClientByIdRequest.data.client);
        setClients((prev) => newClients);
      }

      let newItems = [];
      let newArticles = [...articles];

      state.itemsToCheckIn.map(async (itemToCheckIn, i) => {
        let productExists;

        salesRequest.data.items.rows.map(async (itemRow) => {
          let productExists = false;

          if (itemToCheckIn.id == itemRow.id) {
            productExists = true;
          } else {
            productExists = false;
          }
        });

        if (!productExists) {
          let productRequest = await axiosAuth.get(
            "/item?id=" + itemToCheckIn.id
          );

          newArticles.push(productRequest.data.item);
        }

        newItems.push({
          id: itemToCheckIn.id,
          qty: itemToCheckIn.qty,
          name: itemToCheckIn.name,
          price: itemToCheckIn.closedPrice,
          subtotal:
            String(itemToCheckIn.closedPrice).replaceAll(",", "") *
            itemToCheckIn.qty,
          iva: itemToCheckIn.iva,
        });
      });

      setArticles((prev) => newArticles);

      setTimeout(function () {
        Array(10 - state.itemsToCheckIn.length)
          .fill(0)
          .map((_, i) => {
            newItems.push({
              id: 0,
              qty: 0,
              name: "",
              price: 0,
              subtotal: 0,
              iva: "",
            });
          });

        setItems((prev) => newItems);
        setIsFetching((prev) => false);
      }, 1200);
    } else {
      let newItems = [];
      Array(10)
        .fill(0)
        .map((_, i) => {
          newItems.push({
            id: 0,
            qty: 0,
            name: "",
            price: 0,
            subtotal: 0,
            iva: "",
          });
        });

      setItems((prev) => newItems);
      setIsFetching((prev) => false);
    }
    genDataCurrencies(salesRequest.data.currencies);
  }
  function genDataCurrencies(curre) {
    console.log(curre);
    let datas = {
      totalAmount: totalAmountSin,
      rates: curre.map((currency) => {
        if (currency.exchangerates && currency.exchangerates.length > 0) {
          let sortedExchangeRates = currency.exchangerates.sort(
            (a, b) => a.id - b.id
          );

          let lastExchangeRate =
            sortedExchangeRates[sortedExchangeRates.length - 1];

          return {
            id: currency.id,
            amount: lastExchangeRate.price,
            name: currency.name,
          };
        } else {
          return {
            id: currency.id,
            amount: 0,
            name: currency.name,
          };
        }
      }),
    };
    console.log(datas);
    setExchangeData(datas);
    setReceivedValues(Array(datas.rates.length).fill(0));
    setRestReceivedValues(Array(datas.rates.length).fill(0));
  }
  useEffect(() => {
    if (willFocus) {
      duesRef.current.focus();
    }
  }, [willFocus]);

  useEffect(() => {
    searchItems(searchText, "modal");
  }, [currentPage]);

  useEffect(() => {
    if (willFocusB) {
      duesRefB.current.focus();
    }
  }, [willFocusB]);

  useEffect(() => {
    fetchSales();
    dispatch(setTitle("Ventas"));
    const savedCurrencyId = localStorage.getItem("selectedCurrencyId");
    if (savedCurrencyId) {
      setDefaultCurrency(Number(savedCurrencyId));
      setCurrencyId(Number(savedCurrencyId));
    }

    const currency = currencies.find((c) => c.id == currencyId);
    if (currency) {
      setSelectedCurrency(currency.name);
    }
  }, []);

  useEffect(() => {
    setClient(clients.filter((client) => client.id === clientId)[0]);
  }, [clientId, clients]);

  useEffect(() => {
    filterPaymentMethods(saleConditionId);
  }, [saleConditionId, paymentMethods]);

  useEffect(() => {
    let totalAmountSum = 0;
    let toItemsQty = 0;
    items.map((item) => {
      if (item.id > 0) {
        toItemsQty++;
      }
      totalAmountSum +=
        item.qty * Number(String(item.price).replaceAll(",", ""));
    });
    setItemsQty(toItemsQty);

    setTotalAmount(numberWithCommas(totalAmountSum));
    setTotalAmountSin(totalAmountSum);

    if (items.length > 0) {
      let isFull = true;

      items.map((item) => {
        if (item.name == "") {
          isFull = false;
        }
      });

      let newItems = [...items];

      if (isFull) {
        newItems.push({
          id: 0,
          qty: 0,
          name: "",
          price: 0,
          subtotal: 0,
          iva: "",
        });

        setItems(newItems);
      }
    }

    if (
      saleConditionId == 2 &&
      filteredPaymentMethods[0].amount > 0 &&
      filteredPaymentMethods[1].amount > 0
    ) {
      let newDues = [];
      for (var due of Array(filteredPaymentMethods[0].amount).keys()) {
        let date = new Date();
        newDues.push({
          number: due + 1,
          expirationDate: formatDate(
            addDays(date, Number(filteredPaymentMethods[1].amount) * due)
          ),
          amount: Math.ceil(
            Number(String(totalAmountSum).replaceAll(",", "")) /
            filteredPaymentMethods[0].amount
          ),
        });
      }
      setDues(newDues);
    }

  }, [items, saleConditionId, filteredPaymentMethods]);

  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  useEffect(() => {
    filteredPaymentMethods.map((filteredPaymentMethod) => {
      if (filteredPaymentMethod.amount > 0) {
        setFilteredPaymentMethodsCompleted(true);
      }
    });
    if (
      saleConditionId == 2 &&
      filteredPaymentMethods[0].amount > 0 &&
      filteredPaymentMethods[1].amount > 0
    ) {
      let newDues = [];
      for (var due of Array(filteredPaymentMethods[0].amount).keys()) {
        let date = new Date();
        newDues.push({
          number: due + 1,
          expirationDate: formatDate(
            addDays(date, Number(filteredPaymentMethods[1].amount) * due)
          ),
          amount: Math.ceil(
            Number(String(totalAmount).replaceAll(",", "")) /
            Number(filteredPaymentMethods[0].amount)
          ),
        });
      }
      /*    let newdate = new Date(date);
         console.log("fecha nueva:"+newdate)
   console.log("funcion add",addDays(date, Number(filteredPaymentMethods[1].amount) * due));
   const testDate = addDays(date, 1);  // Intenta sumar un solo día
   console.log("Fecha actual:", date);
   console.log("Fecha con un día más:", testDate);
   console.log("Días a sumar:", Number(filteredPaymentMethods[1].amount) * due); */
      setDues(newDues);
    } else {
      let newDues = [];
      setDues(newDues);
    }
  }, [filteredPaymentMethods]);
  const handleReceivedChange = (index, value) => {
    value = value.replace(/^0+(?!$)/, "");
    const cleanValue = value.replace(/,/g, "");
    const newReceivedValues = [...receivedValues];
    newReceivedValues[index] = cleanValue || 0;
    setReceivedValues(newReceivedValues);
    calculateRemainingAmount(newReceivedValues);
  };

  const calculateRemainingAmount = (receivedValues) => {
    let remainingAmount = totalAmountSin;
    const defaultCurrencyRate = exchangeData.rates.find(
      (rate) => rate.id === defaultCurrency
    ).amount;

    receivedValues.forEach((value, index) => {
      const currency = exchangeData.rates[index];
      const amountInDefaultCurrency =
        value * (currency.id === defaultCurrency ? 1 : currency.amount);
      remainingAmount -= amountInDefaultCurrency;
    });

    const newRestReceivedValues = exchangeData.rates.map((currency) => {
      const amountInDefaultCurrency =
        currency.amount === defaultCurrencyRate
          ? remainingAmount
          : remainingAmount / currency.amount;
      return amountInDefaultCurrency.toFixed(2);
    });

    setRestReceivedValues(newRestReceivedValues);
    setTotalAmountChange(remainingAmount);
  };

  const filterPaymentMethods = (saleConditionId) => {
    let newFilteredPaymentMethods = [];

    paymentMethods.map((paymentMethod) => {
      if (paymentMethod.saleConditionId == saleConditionId) {
        //paymentMethod.amount = null;
        newFilteredPaymentMethods.push(paymentMethod);
      }
    });

    setFilteredPaymentMethods(newFilteredPaymentMethods);
  };

  const clearFields = () => {
    setSaleId(0);
    setClientId(0);
    setAccount({});
    setAccountId();
    setDepositId(0);
    setCurrencyId(1);
    setSaleConditionId(1);
    let newItems = [];
    let newDues = [];

    Array(10)
      .fill(0)
      .map((_, i) => {
        newItems.push({
          id: 0,
          qty: 0,
          name: "",
          price: 0,
          subtotal: 0,
          iva: "",
        });
      });

    setItems(newItems);
    setDues(newDues);

    paymentMethods.map((paymentMethod) => {
      if (paymentMethod.saleConditionId == saleConditionId) {
        paymentMethod.amount = null;
      }
    });

    setDeleted(0);
    setChecks([
      {
        bankId: 0,
        name: "",
        number: 0,
        depositNumber: 0,
        issueDate: "",
        expirationDate: "",
        amount: 0,
        currencyId: 0,
      },
    ]);
  };

  useEffect(() => {
    let account = accounts.filter((account) => account.id == accountId)[0];
    setAccount(account);

  }, [accountId]);

  const handleSubmit = async () => {
    if (action == "edit") {
      return false;
    }

    if (
      clientId === 0 ||
      sellerId === 0 ||
      boxId === 0 ||
      currencyId === 0 ||
      number === 0 ||
      branchId === 0 ||
      depositId === 0 ||
      companyId === 0 ||
      saleConditionId === 0
    ) {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    var hasItems = false;
    var hasPayments = false;
    var mustSetAccount = false;
    var mustCompleteChecks = false;
    var itemsToSend = [];
    var paymentsToSend = [];

    items.map((item) => {
      if (item.id > 0) {
        hasItems = true;
        itemsToSend.push(item);
      }
    });

    filteredPaymentMethods.map((paymentMethod) => {
      if (paymentMethod.amount > 0) {
        hasPayments = true;
        paymentsToSend.push(paymentMethod);
        if (paymentMethod.id == 3 && accountId == 0) {
          mustSetAccount = true;
        }

        if (paymentMethod.id == 2) {
          checks.map((check) => {
            if (check.amount > 0) {
              if (
                check.bankId == 0 ||
                check.currencyId == 0 ||
                check.issueDate == "" ||
                check.expirationDate == ""
              ) {
                mustCompleteChecks = true;
              }
            }
          });
        }
      }
    });

    if (mustCompleteChecks) {
      alert("Debe completar los datos de los cheques para seguir");
      return false;
    }

    if (mustSetAccount) {
      alert("Debe elegir una cuenta a la cual transferir los fondos");
      return false;
    }

    if (saleConditionId == 2 && dues.length <= 0) {
      alert("Debes agregar cuotas para continuar.");
      return false;
    }

    if (!hasItems) {
      alert("Debes agregar articulos para continuar.");
      return false;
    }

    if (!hasPayments) {
      alert("Debes agregar un monto de pago para continuar.");
      return false;
    }

    let createdSaleRequest = await axiosAuth.post("/sales", {
      clientId,
      sellerId,
      priceId,
      boxId,
      currencyId,
      branchId,
      depositId,
      companyId,
      checks,
      date,
      saleconditionId: saleConditionId,
      salesdetail: itemsToSend,
      payments: paymentsToSend,
      accountId,
      expeditionPoint,
      number,
      stamped,
      dues,
      deleted,
    });

    if (createdSaleRequest.data.error) {
      if (createdSaleRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    let newSales = [...sales];
    newSales.push(createdSaleRequest.data);
    setSales(newSales);
    toast.success("Venta creada correctamente!");
    if (window.confirm("Desea imprimir factura ?")) {
      clearFields();
      fetchSales();
      if (companyId == 11) {
        //factura topfit
        window.open("/imprimirfacturaTop/" + createdSaleRequest.data.id);
      } else if (companyId == 9) {
        //factura santa ana
        if(saleConditionId == 3){
          createdSaleRequest.data.salesdetails = createdSaleRequest.data.salesdetails.map(detail => {
            // Añade el total multiplicando price por quantity
            return {
              ...detail,
              subtotal: detail.price * detail.quantity
            };
          });
          //calcula la suma total de la columna "total"
           const totalSum = createdSaleRequest.data.salesdetails.reduce((sum, detail) => {
            return sum + detail.subtotal;
          }, 0); 
  
          // Añade la suma total al objeto principal
          createdSaleRequest.data.totalventa = totalSum;
  
          print('Tickets', createdSaleRequest.data, "ticket_venta.jasper");
        }else{
          window.open("/imprimirfacturasantaana/" + createdSaleRequest.data.id);
         }
      } else if(companyId==3){
        createdSaleRequest.data.salesdetails = createdSaleRequest.data.salesdetails.map(detail => {
          // Añade el total multiplicando price por quantity
          return {
            ...detail,
            subtotal: detail.price * detail.quantity
          };
        });
        //calcula la suma total de la columna "total"
         const totalSum = createdSaleRequest.data.salesdetails.reduce((sum, detail) => {
          return sum + detail.subtotal;
        }, 0); 

        // Añade la suma total al objeto principal
        createdSaleRequest.data.totalventa = totalSum;

        print('Tickets', createdSaleRequest.data, "ticket_venta.jasper");
        //console.log(createdSaleRequest.data)
        //
      }else{
        window.open("/imprimirfactura/"+ createdSaleRequest.data.id);
      }
    } else {
      clearFields();
      fetchSales();
    }

  };

  const searchClients = async (search) => {
    const searchClientsRequest = await axiosAuth.get(
      "/searchClients?search=" + search
    );

    setClients(searchClientsRequest.data.clients.rows);
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 6; // Number of page numbers to display

    if (totalPages <= maxPageNumbers) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= maxPageNumbers - 2) {
        for (let i = 1; i < maxPageNumbers; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      } else if (currentPage >= totalPages - 2) {
        pageNumbers.push(1);
        pageNumbers.push("...");
        for (let i = totalPages - (maxPageNumbers - 2); i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push("...");
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };

  const searchItems = async (search, source) => {
    let boole = source === "modal" ? 0 : 1;
    const searchItemsRequest = await axiosAuth.get(
      `/searchItems?search=${search}&page=${currentPage}&boole=${boole}`
    );
    const newItems = searchItemsRequest.data.items.rows.filter(
      (newItem) =>
        !articles.some((existingItem) => existingItem.id === newItem.id)
    );
    articles.push(...newItems);
    setArticles(articles);
    setSearchArticles(searchItemsRequest.data.items.rows);
    setTotalPage(searchItemsRequest.data.items.pages);

    if (source === "barcode" && barcodeSearch) {
      const newItem = searchItemsRequest.data.items.rows[0];
      let nextEmptyIndex = null;
      let newItems = [...items];

      for (let i = 0; i < newItems.length; i++) {
        if (newItems[i].id === 0) {
          nextEmptyIndex = i;
          break;
        }
      }

      if (nextEmptyIndex === null) {
        newItems.push({
          id: newItem.id,
          qty: 1,
          name: newItem.name,
          price: newItem.price,
          subtotal: newItem.price,
          iva: newItem.iva,
        });
      } else {
        newItems[nextEmptyIndex] = {
          id: newItem.id,
          qty: 1,
          name: newItem.name,
          price: newItem.price,
          subtotal: newItem.price,
          iva: newItem.iva,
        };
      }

      setItems(newItems);
      setBarcodeSearch("");
      focusBarcodeInput();
    }
    if (source === "modaldes") {
      const newItem = searchItemsRequest.data.items.rows[0];
      if (totalAmountSin) {
        setOpendes(true);
        setItemDesc(newItem);
      } else {
        alert("No hay productos sobre el cual aplicar descuentos")
      }


      /*   */

    }
  };

  const handleCurrencyChange = (e) => {
    const selectedCurrencyId = e.target.value;
    //console.log(selectedCurrencyId);
    setCurrencyId(selectedCurrencyId);
    setDefaultCurrency(selectedCurrencyId);

    localStorage.setItem("selectedCurrencyId", selectedCurrencyId);
    const currency = currencies.find((c) => c.id == selectedCurrencyId);
    if (currency) {
      setSelectedCurrency(currency.name);
    }
    // console.log(currencies);
  };

  const handleChangeAmount = (e) => {
    const valor = e.target.value;
    setValueDesc(valor);
    if (totalAmountSin) {
      const total = (valor / totalAmountSin) * 100; // el monto siempre del cuesto pero tiene que dar como resulta el porcetaje por que ese calculo se guarda en el segundo input me explico?
      setValueDescPor((total).toFixed(2))
    }
  }
  const handleChangeAmountPor = (e) => {
    const valor = e.target.value;
    setValueDescPor(valor);
    if (totalAmountSin) {
      const totalGuaranies = totalAmountSin - (totalAmountSin - (totalAmountSin * valor) / 100);
      setValueDesc(totalGuaranies)
    }
  }

  async function procesarArticulos(articles) {
    for (const articulo of articles) {
      const respuesta = await axiosAuth.get(`/stocks/${articulo.id}`);
      const existencia = respuesta.data.stock.existence;
      console.log(`Artículo ${articulo.id}: Existencia = ${existencia}`);
    }
  }
  const focusBarcodeInput = () => {
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus();
    }
  };
  const deleteRow = (i) => {
    const newItems = [...items];
    newItems.splice(i, 1);
    setItems(newItems);
  };
  const deleteSale = async (saleId) => {
    if (window.confirm("Desea eliminar esta venta ?")) {
      let saleDeleteRequest = await axiosAuth.delete("/sales", {
        params: { id: saleId },
      });

      if (saleDeleteRequest.data.error) {
        if (saleDeleteRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newSalesObj = [];

      sales.map((sale) => {
        if (sale.id !== saleId) {
          newSalesObj.push(sale);
        } else {
          if (user.roleId == 1) {
            sale.deleted = 1;
            newSalesObj.push(sale);
          }
        }
        return "";
      });

      setSales(newSalesObj);
    }
  };
  const getCurrencyName = (id) => {
    const currency = exchangeData.rates.find((rate) => rate.id == id);
    return currency ? currency.name : "";
  };
  const getCurrencyAmount = (id) => {
    const currency = exchangeData.rates.find((rate) => rate.id == id);
    return currency ? currency.amount : "";
  };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };


  async function print(documentType, datos, nameReport) {
    // Recuperar el mapeo de impresoras desde el localStorage
    const documentPrinters = JSON.parse(localStorage.getItem('documentPrinters'));

    if (!documentPrinters || !documentPrinters[documentType]) {
      alert('No se ha asignado una impresora para este tipo de documento.');
      return;
    }
    const selectedPrinter = documentPrinters[documentType];
    console.log('Imprimiendo el tipo de documento:', documentType, 'con la impresora:', selectedPrinter);
    try {
      // Envía la imagen al servidor en formato Base64
      const response = await axiosAuth.post('http://localhost:8080/print', {
        printerName: selectedPrinter,
        datos, // Envía la imagen en Base64
        nameReport,
      });
      console.log("datos de la venta:")
      console.log(datos);
      console.log('Respuesta del servidor:', response.data);
    } catch (error) {
      console.error('Error al enviar la imagen:', error);
    }
  };


  return (
    <>
      <Layout>
        {/*  {companyId == 11
         && <PaymentAlert />} */}
        <Transition.Root show={itemsOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              setItemsOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 w-[800px]"
                    style={{ marginTop: 45 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            Buscar articulos
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full">
                              <div class="relative mt-2 rounded-md shadow-sm">
                                <input
                                  type="text"
                                  placeholder="Busque aqui el articulo por nombre o codigo de barras"
                                  class="text-modal-search block w-full rounded-md border-0 py-2 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                    searchItems(e.target.value, "modal");
                                  }}
                                  style={{ fontSize: "13px" }}
                                />
                              </div>

                              <ul className="max-h-[50vh] overflow-auto">
                                {searchArticles.length == 0 ? (
                                  <li className="flex flex-row m-1 p-2">
                                    <div className="text-center w-full">
                                      No encontramos resultados
                                    </div>
                                  </li>
                                ) : (
                                  searchArticles.map((article) => (
                                    <li
                                      key={article.id}
                                      className="flex flex-row m-1 hover:bg-gray-200 cursor-pointer p-2"
                                      style={{
                                        borderBottom: "1px solid lightgray",
                                      }}
                                      onClick={() => {
                                        let nextEmptyIndex = null;
                                        let newItems = [...items];
                                        const BreakException = {};

                                        try {
                                          newItems.forEach((newItem, i) => {
                                            if (newItem.id === 0) {
                                              nextEmptyIndex = i;
                                              throw BreakException;
                                            }
                                          });
                                        } catch (e) {
                                          if (e !== BreakException) throw e;
                                        }

                                        if (nextEmptyIndex === null) {
                                          newItems.push({
                                            id: article.id,
                                            qty: 1,
                                            name: article.name,
                                            price: article.price,
                                            subtotal: article.price,
                                            iva: article.iva,
                                          });
                                        } else {
                                          newItems[nextEmptyIndex] = {
                                            id: article.id,
                                            qty: 1,
                                            name: article.name,
                                            price: article.price,
                                            subtotal: article.price,
                                            iva: article.iva,
                                          };
                                        }

                                        setItems((prev) => newItems);
                                        setItemsOpen(false);
                                      }}
                                    >
                                      <div>
                                        {article?.image ? (
                                          <img
                                            className="h-[70px] m-2 mr-5"
                                            src={`${endpoint}itemImages/${article.image}`}
                                            alt={article.name}
                                          />
                                        ) : (
                                          <img
                                            className="h-[70px] m-2 mr-5"
                                            src={`${endpoint}itemImages/default.png`}
                                            alt="default"
                                          />
                                        )}
                                      </div>
                                      <div className="flex flex-col text-left">
                                        <label className="!text-[9px]">
                                          <span
                                            className="!text-[11px]"
                                            style={{ fontWeight: "900" }}
                                          >
                                            {article.name}
                                          </span>
                                        </label>
                                        <label
                                          className="!text-[11px]"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          <span
                                            className="!text-[9px]"
                                            style={{ fontWeight: "bold" }}
                                          >
                                            PRECIO:
                                          </span>{" "}
                                          {numberWithCommas(article.price)}
                                        </label>
                                        <label
                                          className="!text-[11px]"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          <span
                                            className="!text-[9px]"
                                            style={{ fontWeight: "bold" }}
                                          >
                                            COD BARRAS:
                                          </span>{" "}
                                          {article.barcode}
                                        </label>
                                        <label
                                          className="!text-[11px]"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          <span
                                            className="!text-[9px]"
                                            style={{ fontWeight: "bold" }}
                                          >
                                            STOCK:
                                          </span>
                                        </label>
                                      </div>
                                    </li>
                                  ))
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="bg-gray-50 px-4 py-3 justify-right flex sm:px-6"
                      style={{ justifyContent: "right" }}
                    >
                      <nav className="flex">
                        <button
                          onClick={handlePrev}
                          disabled={currentPage === 1}
                          className={`h-[49px] px-4 py-2 mr-2 rounded focus:outline-none focus:ring bg-gray-600${currentPage === 1
                              ? "text-gray-500 bg-gray-200 cursor-not-allowed"
                              : "text-gray-700 bg-gray-200 hover:bg-gray-300 bg-gray-600"
                            }`}
                        >
                          Anterior
                        </button>
                        <ul className="flex">
                          {renderPageNumbers().map((page, index) => (
                            <li key={index}>
                              {page === "..." ? (
                                <span className="px-4 py-2 mx-1">...</span>
                              ) : (
                                <button
                                  onClick={() => handleClick(page)}
                                  className={`h-[49px] w-[49px] px-4 py-2 mx-1 rounded focus:outline-none focus:ring ${currentPage === page
                                      ? "text-white bg-blue-500 hover:bg-blue-600"
                                      : "text-gray-700 bg-gray-600 hover:bg-gray-300"
                                    }`}
                                >
                                  {page}
                                </button>
                              )}
                            </li>
                          ))}
                        </ul>
                        <button
                          onClick={handleNext}
                          disabled={currentPage === totalPages}
                          className={`h-[49px] px-4 py-2 ml-2 rounded focus:outline-none focus:ring bg-gray-600 ${currentPage === totalPages
                              ? "text-gray-500 bg-gray-200 cursor-not-allowed"
                              : "text-gray-700 bg-gray-200 hover:bg-gray-300"
                            }`}
                        >
                          Siguiente
                        </button>
                      </nav>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-right m-1 flex-col justify-right items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          setItemsOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={checksOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden bg-white text-center shadow-xl transition-all sm:my-8 w-[580px] rounded-3xl">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center w-full font-regular leading-6 primary-color text-2xl"
                          >
                            Cheques
                          </Dialog.Title>
                          <div className="mt-2">
                            <div className="w-full">
                              <form
                                className="bg-white overflow-hidden overflow-x-auto"
                                onSubmit={(e) => e.preventDefault()}
                              >
                                <table className="sales-table text-[11px] !w-[1024px]">
                                  <thead>
                                    <tr>
                                      <th>Banco</th>
                                      <th>Nombre</th>
                                      <th>Numero</th>
                                      <th>Numero de Deposito</th>
                                      <th>Emision</th>
                                      <th>Vencimiento</th>
                                      <th>Monto</th>
                                      <th>Moneda</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {checks.map((check, i) => (
                                      <tr>
                                        <td>
                                          <select
                                            className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[1.85rem]"
                                            style={{ lineHeight: 0.85 }}
                                            name="bank"
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, c) => {
                                                if (c == i) {
                                                  newCheck.bankId =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                            value={check.bankId}
                                          >
                                            <option value={0}>
                                              Elija un banco
                                            </option>
                                            {banks.map((bank) => (
                                              <option value={bank.id}>
                                                {bank.name}
                                              </option>
                                            ))}
                                          </select>
                                        </td>
                                        <td>
                                          <input
                                            className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6"
                                            name="name"
                                            type="text"
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, c) => {
                                                if (c == i) {
                                                  newCheck.name =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                            value={check.name}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6"
                                            name="number"
                                            type="text"
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, c) => {
                                                if (c == i) {
                                                  newCheck.number =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                            value={check.name}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6"
                                            name="depositNumber"
                                            type="text"
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, c) => {
                                                if (c == i) {
                                                  newCheck.depositNumber =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                            value={check.name}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6"
                                            name="issueDate"
                                            type="date"
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, c) => {
                                                if (c == i) {
                                                  newCheck.issueDate =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                            value={check.issueDate}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6"
                                            name="expirationDate"
                                            type="date"
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, c) => {
                                                if (c == i) {
                                                  newCheck.expirationDate =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                            value={check.expirationDate}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6"
                                            name="amount"
                                            type="number"
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, c) => {
                                                if (c == i) {
                                                  newCheck.amount =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                            value={numberWithCommas(
                                              check.amount
                                            )}
                                          />
                                        </td>
                                        <td>
                                          <select
                                            className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[1.85rem]"
                                            style={{ lineHeight: 0.85 }}
                                            name="currency"
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, c) => {
                                                if (c == i) {
                                                  newCheck.currencyId =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                              console.log(e.target.value);
                                              setDefaultCurrency(
                                                e.target.value
                                              );
                                            }}
                                            value={check.currencyId}
                                          >
                                            <option value={0}>
                                              Elija una moneda
                                            </option>
                                            {currencies.map((currency) => (
                                              <option value={currency.id}>
                                                {currency.name}
                                              </option>
                                            ))}
                                          </select>
                                        </td>
                                        <td>
                                          {i == checks.length - 1 && (
                                            <button
                                              onClick={() => {
                                                let newChecks = [...checks];
                                                newChecks.push({
                                                  bankId: 0,
                                                  name: "",
                                                  issueDate: "",
                                                  expirationDate: "",
                                                  amount: 0,
                                                  currencyId: 0,
                                                });
                                                setChecks(newChecks);
                                              }}
                                              className="inline-flex w-full rounded-3xl font-family-thin secondary-bg-color justify-center px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto font-family-thin"
                                            >
                                              Agregar
                                            </button>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full rounded-3xl font-family-thin secondary-bg-color justify-center px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto font-family-thin"
                        onClick={() => {
                          setChecks([
                            {
                              bankId: 0,
                              name: "",
                              issueDate: "",
                              expirationDate: "",
                              amount: 0,
                              currencyId: 0,
                            },
                          ]);
                          setChecksOpen(false);
                        }}
                      >
                        CANCELAR
                      </button>
                      <button
                        type="button"
                        className="inline-flex w-full align-center rounded-3xl align-items-center justify-center primary-color-bg px-3 py-2 text-sm font-semibold  shadow-sm sm:ml-3 sm:w-auto font-family-thin"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          setChecksOpen(false);
                        }}
                      >
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={opendes} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpendes(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white text-center shadow-xl transition-all sm:my-8 w-[340px]">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center w-full font-regular leading-6 primary-color text-2xl"
                          >
                            DESCUENTO
                          </Dialog.Title>
                          <div className="mt-2">
                            <div className="w-full">
                              <form className="bg-white rounded-3xl">
                                <label
                                  className="!text-[11px]"
                                  style={{ fontWeight: "bold" }}
                                >
                                  <span
                                    className="!text-[9px]"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Monto en Guaranies
                                  </span>
                                </label>
                                <input
                                  placeholder="Monto en guaranies"

                                  value={valueDesc}
                                  className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                  onChange={handleChangeAmount}
                                />
                                <label
                                  className="!text-[11px]"
                                  style={{ fontWeight: "bold" }}
                                >
                                  <span
                                    className="!text-[9px]"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Porcentaje a Aplicar
                                  </span>
                                </label>
                                <input
                                  placeholder="Porcentaje de descuento"

                                  value={valuePorcentaje}
                                  className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                  onChange={handleChangeAmountPor}
                                />
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full align-center rounded-3xl align-items-center justify-center primary-color-bg px-3 py-2 text-sm font-semibold  shadow-sm sm:ml-3 sm:w-auto font-family-thin"
                        style={{ alignItems: "center" }}
                        onClick={() => {

                          let nextEmptyIndex = null;
                          let newItems = [...items];


                          for (let i = 0; i < newItems.length; i++) {
                            if (newItems)
                              if (newItems[i].id === 0) {
                                nextEmptyIndex = i;
                                break;
                              }
                          }

                          if (nextEmptyIndex === null) {
                            newItems.push({
                              id: itemDesc.id,
                              qty: 1,
                              name: itemDesc.name,
                              price: -valueDesc,
                              subtotal: -valueDesc,
                              iva: itemDesc.iva,
                            });
                          } else {
                            newItems[nextEmptyIndex] = {
                              id: itemDesc.id,
                              qty: 1,
                              name: itemDesc.name,
                              price: -valueDesc,
                              subtotal: -valueDesc,
                              iva: itemDesc.iva,
                            };
                          }

                          setItems(newItems);
                          setOpendes(false);
                        }}
                      >
                        ACEPTAR
                      </button>
                      <button
                        type="button"
                        className="inline-flex w-full rounded-3xl font-family-thin secondary-bg-color justify-center px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto font-family-thin"
                        onClick={() => {
                          setAccountId(0);
                          setAccount([]);
                          setOpendes(false);
                        }}
                      >
                        CANCELAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white text-center shadow-xl transition-all sm:my-8 w-[340px]">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center w-full font-regular leading-6 primary-color text-2xl"
                          >
                            Transferencia
                          </Dialog.Title>
                          <div className="mt-2">
                            <div className="w-full">
                              <form className="bg-white rounded-3xl">
                                <select
                                  name="accountId"
                                  value={accountId}
                                  className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                  onChange={(e) => setAccountId(e.target.value)}
                                >
                                  <option value={0}>Elija una cuenta</option>
                                  {accounts.map((account) => (
                                    <option value={account.id}>
                                      {account.name}
                                    </option>
                                  ))}
                                </select>

                                <input
                                  readOnly
                                  disabled
                                  placeholder="Nro. de Cuenta"
                                  name="accountNumber"
                                  value={account?.number}
                                  className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                />

                                <input
                                  readOnly
                                  disabled
                                  placeholder="Propietario"
                                  name="accountOwner"
                                  value={account?.owner}
                                  className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                />

                                <input
                                  readOnly
                                  disabled
                                  placeholder="Banco"
                                  name="accountBank"
                                  value={account?.bank?.name}
                                  className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                />

                                <input
                                  readOnly
                                  disabled
                                  placeholder="Tipo de cuenta"
                                  name="accounType"
                                  value={account?.accountype?.description}
                                  className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                />

                                <input
                                  readOnly
                                  disabled
                                  placeholder="Moneda"
                                  name="accounCurrency"
                                  value={account?.currency?.name}
                                  className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                />
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full rounded-3xl font-family-thin secondary-bg-color justify-center px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto font-family-thin"
                        onClick={() => {
                          setAccountId(0);
                          setAccount([]);
                          setOpen(false);
                        }}
                      >
                        CANCELAR
                      </button>
                      <button
                        type="button"
                        className="inline-flex w-full align-center rounded-3xl align-items-center justify-center primary-color-bg px-3 py-2 text-sm font-semibold  shadow-sm sm:ml-3 sm:w-auto font-family-thin"
                        style={{ alignItems: "center" }}
                        onClick={() => setOpen(false)}
                      >
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={true}>
          <div className="flex flex-row">
            <div className="w-[60%] md:w-[20%] border-slate-200 border-r-2">
              <h1 className="ml-5 mt-5 text-2xl  title secondary-color font-family-thin">
                Ventas
              </h1>
              <DateComponent className="!text-[14px] sm:block hidden" />
            </div>

            {width > 550 ? (
              <>
                <div className="w-[75%] pt-2 pl-2">
                  <div className="flex flex-row w-full">
                    <div className="w-[50%]">
                      <div className="mb-[1px]">
                        <ComboBox
                          data={clients}
                          setData={setClientId}
                          value={clientId}
                          placeholder="CLIENTE"
                          className="h-[22px] w-[99.7%] !text-[10px]"
                          search={searchClients}
                        />
                      </div>

                      <div className="mb-[-1px]">
                        <input
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[22px] !text-[11px]"
                          id="RUC"
                          type="text"
                          disabled
                          readOnly
                          tabIndex={-1}
                          value={client?.ruc || ""}
                          placeholder="RUC"
                          onChange={(e) => { }}
                        />
                      </div>

                      <div className="mb-[-1px]">
                        <input
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[22px] !text-[11px]"
                          id="address"
                          type="text"
                          readOnly
                          disabled
                          tabIndex={-1}
                          value={client?.address || ""}
                          placeholder="DIRECCION"
                          onChange={(e) => { }}
                        />
                      </div>

                      <div>
                        <input
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[24px] !text-[11px]"
                          id="phone"
                          type="text"
                          readOnly
                          disabled
                          tabIndex={-1}
                          value={client?.phone || ""}
                          placeholder="TELEFONO"
                          onChange={(e) => { }}
                        />
                      </div>
                    </div>

                    <div className="w-[45%] ml-[1px]">
                      <div className="mb-[0px] flex flex-row">
                        <div className="w-full mt-[-1px] ml-[-1px]">
                          <select
                            className="w-[99%] ml-[1px] input-bg-color appearance-none border w-[100.6%] py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[22px] !text-[11px] uppercase"
                            id="branch"
                            readOnly
                            disabled
                            tabIndex={-1}
                            value={branchId}
                            onChange={(e) => {
                              setBranchId(e.target.value);
                            }}
                          >
                            <option value={0}>Sucursal</option>
                            <option value={user.branch.id}>
                              Sucursal: {user.branch.description}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="mb-[2px]">
                        <div className="w-full">
                          <select
                            className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight h-[22px] !text-[11px] uppercase"
                            id="deposit"
                            value={depositId}
                            onChange={(e) => {
                              setDepositId(e.target.value);
                            }}
                          >
                            <option value={0}>Elija un deposito</option>
                            {deposits.map((deposit) => (
                              <option value={deposit.id}>
                                Deposito: {deposit.description}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mt-[1px]">
                          <select
                            className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight uppercase h-[22px] !text-[11px]"
                            id="branch"
                            value={currencyId}
                            onChange={handleCurrencyChange}
                          >
                            {currencies.map((currency, i) => (
                              <option value={currency.id} key={i}>
                                Moneda: {currency.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div>
                        <select
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight uppercase h-[22px] mt-[-1px] !text-[11px]"
                          id="sellerId"
                          value={sellerId}
                          onChange={(e) => { }}
                        >
                          <option value={0}>Vendedor</option>
                          <option value={user.id}>
                            Vendedor: {user.fullname}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="w-[31%] ml-[1px]">
                      <div className="mb-[1px]">
                        <select
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight capitalize h-[22px] !text-[11px]"
                          id="saleConditionId"
                          value={saleConditionId}
                          disabled={action == "edit" ? true : false}
                          readOnly={action == "edit" ? true : false}
                          onChange={(e) => {
                            setSaleConditionId(e.target.value);
                            filterPaymentMethods(e.target.value);
                          }}
                        >
                          <option value={0}>Condicion de venta</option>
                          {saleConditions.map((saleCondition) => (
                            <option value={saleCondition.id}>
                              {saleCondition.description}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="mb-[1px]">
                        <input
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight h-[22px] !text-[11px]"
                          id="date"
                          value={date}
                          type="date"
                          placeholder="Fecha"
                          onChange={(e) => {
                            setDate(e.target.value);
                          }}
                        />
                      </div>

                      <div className="mb-[1px] flex flex-row">
                        <input
                          className="input-bg-color appearance-none border w-[49.5%] py-1 px-3 text-gray-700 leading-tight h-[22px] mt-[-1px] mr-[1px] !text-[11px]"
                          id="expeditionPoint"
                          value={expeditionPoint}
                          readOnly
                          disabled
                          type="text"
                          placeholder="Punto de exp."
                          onChange={(e) => { }}
                        />

                        <input
                          className="input-bg-color appearance-none border w-[50%] py-1 px-3 text-gray-700 leading-tight text-right h-[22px] !text-[11px]"
                          id="number"
                          value={number}
                          type="text"
                          placeholder="Numero"
                          onChange={(e) => {
                            setNumber(e.target.value);
                          }}
                        />
                      </div>

                      <div className="mb-[1px] flex flex-row">
                        <select
                          className="input-bg-color appearance-none border w-[50%] py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize mt-[-1px] h-[22px] !text-[11px]"
                          id="boxId"
                          value={boxId}
                          disabled
                          readOnly
                          onChange={(e) => {
                            setBoxId(e.target.value);
                          }}
                        >
                          <option value={0}>Caja</option>
                          <option value={user.box.id}>{user.box.name}</option>
                        </select>

                        <input
                          className="input-bg-color appearance-none border w-[50%] py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-[-1px] h-[22px] !text-[11px]"
                          id="stamped"
                          value={stamped}
                          readOnly
                          disabled
                          type="text"
                          placeholder="Timbrado"
                          onChange={(e) => { }}
                        />
                      </div>
                    </div>

                    <div className="w-[20%] ml-[1px]">
                      {filteredPaymentMethods.map((paymentMethod, i) => (
                        <div className="mb-[1px]">
                          <input
                            ref={duesRefB}
                            readOnly={action == "edit" ? true : false}
                            className={`input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight h-[22px] !text-[11px] ${i > 1 ? "h-[22px]" : ""
                              } ${i === filteredPaymentMethods.length - 1 &&
                                saleConditionId != 2
                                ? "h-[22px]"
                                : ""
                              } ${i === filteredPaymentMethods.length - 1 &&
                                saleConditionId == 2
                                ? "h-[22px]"
                                : ""
                              }`}
                            value={numberWithCommas(paymentMethod.amount)}
                            type="text"
                            placeholder={paymentMethod.name}
                            onClick={() => {
                              if (
                                paymentMethod.id == 2 &&
                                paymentMethod.amount > 0
                              ) {
                                setChecksOpen(true);
                              }
                              if (
                                paymentMethod.id == 3 &&
                                paymentMethod.amount > 0
                              ) {
                                setOpen(true);
                              }
                            }}
                            onChange={(e) => {
                              let newFilteredPaymentMethods = [];
                              paymentMethods.map((paymentMethodMap) => {
                                if (paymentMethodMap.id == paymentMethod.id) {
                                  paymentMethodMap.amount = Number(
                                    String(e.target.value).replaceAll(",", "")
                                  );
                                  newFilteredPaymentMethods.push(
                                    paymentMethodMap
                                  );

                                  if (saleConditionId == 1 && i == 2) {
                                    let typingTimer;
                                    let doneTypingInterval = 1500;

                                    clearTimeout(typingTimer);
                                    typingTimer = setTimeout(function () {
                                      setOpen(true);
                                    }, doneTypingInterval);
                                  }

                                  if (saleConditionId == 1 && i == 1) {
                                    let typingTimer;
                                    let doneTypingInterval = 1500;

                                    clearTimeout(typingTimer);
                                    typingTimer = setTimeout(function () {
                                      setChecksOpen(true);
                                    }, doneTypingInterval);
                                  }
                                } else {
                                  if (
                                    paymentMethodMap.saleConditionId ==
                                    saleConditionId
                                  ) {
                                    newFilteredPaymentMethods.push(
                                      paymentMethodMap
                                    );
                                  }
                                }
                              });
                              setFilteredPaymentMethods(
                                newFilteredPaymentMethods
                              );
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="w-[100%] p-4 mx-auto overflow-auto pb-4 pt-0">
            <div className="bg-white px-4 pb-4 p-3 pt-0 mt-[-5px] text-center rounded-3xl">
              <div className="w-full">
                <div className="mt-5">
                  <div className="w-full flex flex-col">
                    {/* {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="SaleId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="SaleId"
                                      type="text"
                                      value={saleId}
                                    />
                                  </div>
                                )} */}

                    {width > 550 ? (
                      <></>
                    ) : (
                      <>
                        <Tab.Group>
                          <Tab.List className="flex space-x-1 bg-blue-900/20 p-1 mb-[2px]">
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  "w-full  py-1.5 text-sm leading-5 primary-color text-xs",
                                  "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                  clientId == 0 ? "bg-red-300" : "bg-green-200",
                                  selected ? "bt-mobi" : "bt-mobi-se"
                                )
                              }
                            >
                              Cliente
                            </Tab>
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  "w-full py-1.5 text-sm leading-5 primary-color text-xs",
                                  "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",

                                  selected ? "bt-mobi" : "bt-mobi-se"
                                )
                              }
                            >
                              Datos
                            </Tab>
                            <Tab
                              onClick={() => {
                                filterPaymentMethods(saleConditionId);
                              }}
                              className={({ selected }) =>
                                classNames(
                                  "w-full py-1.5 text-sm leading-5 primary-color text-xs",
                                  "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                  selected ? "bt-mobi" : "bt-mobi-se"
                                )
                              }
                            >
                              Detalles
                            </Tab>
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  "w-full py-1.5 text-sm leading-5 primary-color text-xs",
                                  "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                  selected ? "bt-mobi" : "bt-mobi-se"
                                )
                              }
                            >
                              Formas de Pago
                            </Tab>
                          </Tab.List>
                          <Tab.Panels>
                            <Tab.Panel>
                              <Transition.Child
                                as="div"
                                enter="ease-out duration-500"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <div className="mb-[2px]">
                                  <ComboBox
                                    data={clients}
                                    setData={setClientId}
                                    value={clientId}
                                    className="h-[30px]"
                                    search={searchClients}
                                  />
                                </div>

                                <div className="mb-[2px]">
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="RUC"
                                    type="text"
                                    disabled
                                    readOnly
                                    value={client?.ruc || ""}
                                    placeholder="RUC"
                                    onChange={(e) => { }}
                                  />
                                </div>

                                <div className="mb-[2px]">
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="address"
                                    type="text"
                                    readOnly
                                    disabled
                                    value={client?.address || ""}
                                    placeholder="Direccion"
                                    onChange={(e) => { }}
                                  />
                                </div>

                                <div>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="phone"
                                    type="text"
                                    readOnly
                                    disabled
                                    value={client?.phone || ""}
                                    placeholder="Telefono"
                                    onChange={(e) => { }}
                                  />
                                </div>
                              </Transition.Child>
                            </Tab.Panel>
                            <Tab.Panel>
                              <Transition.Child
                                as="div"
                                enter="ease-out duration-500"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <div className="mb-[2px] flex flex-row w-full">
                                  <div className="w-[50%]">
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                      id="branch"
                                      readOnly
                                      disabled
                                      value={branchId}
                                      onChange={(e) => {
                                        setBranchId(e.target.value);
                                      }}
                                    >
                                      <option value={0}>Sucursal</option>
                                      <option value={user.branch.id}>
                                        Sucursal: {user.branch.description}
                                      </option>
                                    </select>
                                  </div>
                                  <div className="w-[50%]">
                                    <select
                                      className="input-bg-color appearance-none border rounded w-[98%] py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-[1px] capitalize h-[29px]"
                                      id="deposit"
                                      value={depositId}
                                      onChange={(e) => {
                                        setDepositId(e.target.value);
                                      }}
                                    >
                                      <option value={0}>
                                        Elija un deposito
                                      </option>
                                      {deposits.map((deposit) => (
                                        <option value={deposit.id}>
                                          {deposit.description}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                <div className="mb-[2px]">
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="branch"
                                    value={currencyId}
                                    onChange={handleCurrencyChange}
                                  >
                                    <option value={0}>Moneda</option>
                                    {currencies.map((currency, i) => (
                                      <option value={currency.id} key={i}>
                                        Moneda: {currency.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                <div className="mb-[2px]">
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="date"
                                    value={date}
                                    type="date"
                                    placeholder="Fecha"
                                    onChange={(e) => {
                                      setDate(e.target.value);
                                    }}
                                  />
                                </div>
                                <div>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="sellerId"
                                    disabled
                                    readOnly
                                    value={sellerId}
                                    onChange={(e) => { }}
                                  >
                                    <option value={0}>Vendedor</option>
                                    <option value={user.id}>
                                      Vendedor: {user.fullname}
                                    </option>
                                  </select>
                                </div>
                              </Transition.Child>
                            </Tab.Panel>
                            <Tab.Panel>
                              <Transition.Child
                                as="div"
                                enter="ease-out duration-500"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <div className="mb-[2px] flex flex-row">
                                  <input
                                    className="input-bg-color appearance-none border rounded w-[50%] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="expeditionPoint"
                                    readOnly
                                    disabled
                                    value={expeditionPoint}
                                    type="text"
                                    placeholder="Punto de exp"
                                    onChange={(e) => { }}
                                  />

                                  <input
                                    className="input-bg-color appearance-none border rounded w-[50%] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="number"
                                    value={number}
                                    type="text"
                                    placeholder="Numero"
                                    onChange={(e) => {
                                      setNumber(e.target.value);
                                    }}
                                  />
                                </div>

                                <div className="mb-[2px] flex flex-row">
                                  <select
                                    className="input-bg-color appearance-none border rounded w-[50%] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="boxId"
                                    value={boxId}
                                    disabled
                                    readOnly
                                    onChange={(e) => {
                                      setBoxId(e.target.value);
                                    }}
                                  >
                                    <option value={0}>Caja</option>
                                    <option value={user.box.id}>
                                      {user.box.name}
                                    </option>
                                  </select>

                                  <input
                                    className="input-bg-color appearance-none border rounded w-[50%] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="stamped"
                                    readOnly
                                    disabled
                                    value={stamped}
                                    type="text"
                                    placeholder="Timbrado"
                                    onChange={(e) => { }}
                                  />
                                </div>

                                <div className="mb-[2px]">
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="saleConditionId"
                                    value={saleConditionId}
                                    disabled={action == "edit" ? true : false}
                                    readOnly={action == "edit" ? true : false}
                                    onChange={(e) => {
                                      setSaleConditionId(e.target.value);
                                      filterPaymentMethods(e.target.value);
                                    }}
                                  >
                                    <option value={0}>
                                      Condicion de venta
                                    </option>
                                    {saleConditions.map((saleCondition) => (
                                      <option value={saleCondition.id}>
                                        {saleCondition.description}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                {filteredPaymentMethods.length > 0 ? (
                                  <div>
                                    <input
                                      readOnly={action == "edit" ? true : false}
                                      className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                      ref={duesRef}
                                      value={numberWithCommas(
                                        filteredPaymentMethods[0]?.amount
                                      )}
                                      placeholder={
                                        filteredPaymentMethods[0]?.name
                                      }
                                      onChange={(e) => {
                                        let newFilteredPaymentMethods = [];
                                        paymentMethods.map((paymentMethod) => {
                                          if (
                                            paymentMethod.id ==
                                            filteredPaymentMethods[0].id
                                          ) {
                                            paymentMethod.amount = Number(
                                              String(e.target.value).replaceAll(
                                                ",",
                                                ""
                                              )
                                            );
                                            newFilteredPaymentMethods.push(
                                              paymentMethod
                                            );
                                          } else {
                                            if (
                                              saleConditionId ==
                                              paymentMethod.saleConditionId
                                            ) {
                                              newFilteredPaymentMethods.push(
                                                paymentMethod
                                              );
                                            }
                                          }
                                        });
                                        setFilteredPaymentMethods(
                                          newFilteredPaymentMethods
                                        );
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </Transition.Child>
                            </Tab.Panel>
                            <Tab.Panel>
                              <Transition.Child
                                as="div"
                                enter="ease-out duration-500"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                {filteredPaymentMethods.map(
                                  (paymentMethod, i) =>
                                    i > 0 && (
                                      <div className="mb-[2px]">
                                        <input
                                          ref={duesRefB}
                                          readOnly={
                                            action == "edit" ? true : false
                                          }
                                          onClick={() => {
                                            if (
                                              paymentMethod.id == 2 &&
                                              paymentMethod.amount > 0
                                            ) {
                                              setChecksOpen(true);
                                            }
                                            if (
                                              paymentMethod.id == 3 &&
                                              paymentMethod.amount > 0
                                            ) {
                                              setOpen(true);
                                            }
                                          }}
                                          className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                          value={numberWithCommas(
                                            paymentMethod.amount
                                          )}
                                          type="text"
                                          placeholder={paymentMethod.name}
                                          onChange={(e) => {
                                            let newFilteredPaymentMethods = [];
                                            paymentMethods.map(
                                              (paymentMethodMap, i) => {
                                                if (
                                                  paymentMethodMap.id ==
                                                  paymentMethod.id
                                                ) {
                                                  paymentMethodMap.amount =
                                                    Number(
                                                      String(
                                                        e.target.value
                                                      ).replaceAll(",", "")
                                                    );
                                                  newFilteredPaymentMethods.push(
                                                    paymentMethodMap
                                                  );

                                                  if (
                                                    saleConditionId == 1 &&
                                                    i == 2
                                                  ) {
                                                    let typingTimer;
                                                    let doneTypingInterval = 1500;

                                                    clearTimeout(typingTimer);
                                                    typingTimer = setTimeout(
                                                      function () {
                                                        setOpen(true);
                                                      },
                                                      doneTypingInterval
                                                    );
                                                  }

                                                  if (
                                                    saleConditionId == 1 &&
                                                    i == 1
                                                  ) {
                                                    let typingTimer;
                                                    let doneTypingInterval = 1500;

                                                    clearTimeout(typingTimer);
                                                    typingTimer = setTimeout(
                                                      function () {
                                                        setChecksOpen(true);
                                                      },
                                                      doneTypingInterval
                                                    );
                                                  }
                                                } else {
                                                  if (
                                                    saleConditionId ==
                                                    paymentMethodMap.saleConditionId
                                                  ) {
                                                    newFilteredPaymentMethods.push(
                                                      paymentMethodMap
                                                    );
                                                  }
                                                }
                                              }
                                            );
                                            setFilteredPaymentMethods(
                                              newFilteredPaymentMethods
                                            );
                                          }}
                                        />
                                      </div>
                                    )
                                )}
                              </Transition.Child>
                            </Tab.Panel>
                          </Tab.Panels>
                        </Tab.Group>
                      </>
                    )}

                    <div className="w-full flex flex-row p-1 pt-0 overflow-hidden">
                      <div className="w-[100%] ">
                        <div className="flex sm:flex-row flex-col w-full">
                          <div
                            className={`sales-table sm:min-w-[795px] ${saleConditionId != 2
                                ? "w-full"
                                : "w-full sm:w-[347%]"
                              }`}
                          >
                            <div className="text-gray">
                              {width > 620 ? (
                                <div></div>
                              ) : (
                                <div className="primary-color-bg !text-white !text-[11px] font-bold uppercase whitespace-nowrap my-2 py-1">
                                  Articulos
                                </div>
                              )}
                              {width > 620 ? (
                                <div
                                  className="tr flex mb-[1px] primary-color-bg !text-white"
                                  style={{ lineHeight: 2 }}
                                >
                                  <div className="w-[10%] text-[15px] font-bold !text-[11px]">
                                    ID
                                  </div>
                                  <div className="w-[43%] text-[15px] font-bold !text-[11px]">
                                    PRODUCTO
                                  </div>
                                  <div className="w-[10%] text-[15px] font-bold !text-[11px]">
                                    CANT
                                  </div>
                                  <div className="w-[15%] text-[15px] font-bold !text-[11px]">
                                    PRECIO
                                  </div>
                                  <div className="w-[15%] text-[15px] font-bold !text-[11px]">
                                    TOTAL
                                  </div>
                                  <div className="w-[9%] text-[15px] font-bold !text-[11px]">
                                    IVA
                                  </div>
                                  <div className="w-[5%] text-[15px] font-bold !text-[11px]">
                                    <FontAwesomeIcon
                                      className="mt-2 cursor-pointer white-color"
                                      icon={faTrash}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <></>
                                // <div
                                //   className="tr flex primary-color-bg !text-white !text-[11px]"
                                //   style={{ lineHeight: 2 }}
                                // >
                                //   <div className="w-[15%] text-[15px] font-bold !text-[11px]">
                                //     ID
                                //   </div>
                                //   <div className="w-[65%] text-[15px] font-bold !text-[11px]">
                                //     PRODUCTO
                                //   </div>
                                //   <div className="w-[10%] text-[15px] font-bold !text-[11px]">
                                //     CANT
                                //   </div>
                                // </div>
                              )}
                            </div>
                            <div className="overflow-y-auto overflow-x-auto h-[260px] vw-xs">
                              {isFetching && (
                                <div className="tr">
                                  <div className="td text-center" colSpan={20}>
                                    <Lottie
                                      animationData={loadingAnimation}
                                      style={{
                                        width: 100,
                                        height: 100,
                                        margin: "0 auto",
                                      }}
                                      loop={true}
                                    />
                                  </div>
                                </div>
                              )}

                              {width > 620
                                ? items.map((item, i) => (
                                  <div className="tr flex border-slate-200 border-b-1">
                                    <div className="td w-[10%] ">
                                      <input
                                        className="input-bg-color py-1 px-3 w-full text-center h-[26px] !text-[10px]"
                                        type="text"
                                        name="code"
                                        value={item.id}
                                      />
                                    </div>

                                    <div className="td w-[43%] ">
                                      <ComboBox
                                        data={articles}
                                        value={item.id}
                                        setData={setItems}
                                        items={items}
                                        index={i}
                                        className={
                                          "h-[26px] !text-[10px] index-" + i
                                        }
                                        search={searchItems}
                                      />
                                    </div>
                                    <div className="td w-[10%]">
                                      <input
                                        className="input-bg-color py-1 px-3 w-full text-center h-[26px] !text-[10px]"
                                        type="text"
                                        name="qty"
                                        value={item.qty}
                                        onChange={(e) => {
                                          let newItems = [...items];
                                          newItems.map((item, it) => {
                                            if (i == it) {
                                              item.qty = e.target.value;
                                              item.subtotal =
                                                String(item.price).replaceAll(
                                                  ",",
                                                  ""
                                                ) * item.qty;
                                              item.subtotal =
                                                numberWithCommas(
                                                  item.subtotal
                                                );
                                            }
                                          });
                                          setItems(newItems);
                                        }}
                                      />
                                    </div>
                                    <div className="td w-[15%] ">
                                      <input
                                        className="input-bg-color py-1 px-3 w-full text-right h-[26px] !text-[10px]"
                                        type="text"
                                        name="price"
                                        value={numberWithCommas(item.price)}
                                        placeholder="Precio"
                                        onChange={(e) => {
                                          let newItems = [...items];
                                          newItems.map((item, it) => {
                                            if (i == it) {
                                              item.price = String(
                                                e.target.value
                                              ).replaceAll(",", "");
                                              item.subtotal =
                                                String(item.price).replaceAll(
                                                  ",",
                                                  ""
                                                ) * item.qty;
                                              item.subtotal =
                                                numberWithCommas(
                                                  item.subtotal
                                                );
                                            }
                                          });
                                          setItems(newItems);
                                        }}
                                      />
                                    </div>
                                    <div className="td w-[15%] ">
                                      <input
                                        className=" py-1 px-3 w-full text-right h-[26px] !text-[10px]"
                                        type="text"
                                        name="subtotal"
                                        value={numberWithCommas(
                                          item.subtotal
                                        )}
                                      />
                                    </div>
                                    <div className="td w-[9%] ">
                                      <input
                                        className="input-bg-color py-1 px-3 w-full text-center h-[26px] !text-[10px]"
                                        type="text"
                                        name="iva"
                                        value={item.iva}
                                      />
                                    </div>
                                    <div className="td w-[5%] ">
                                      <button
                                        className="bg-transparent"
                                        onClick={() => {
                                          deleteRow(i);
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          className="m-1 cursor-pointer secondary-color"
                                          icon={faTrash}
                                        />
                                      </button>
                                    </div>
                                  </div>
                                ))
                                : items.map((item, i) =>
                                  i < itera ? (
                                    <div className="tr flex flex-col">
                                      <div className="w-full flex flex-row">
                                        <div className="td w-[100%] m-[1px] flex flex-row justify-center items-center">
                                          <ComboBox
                                            data={articles}
                                            value={item.id}
                                            setData={setItems}
                                            items={items}
                                            placeholder="Buscar articulos"
                                            index={i}
                                            search={searchItems}
                                            className="h-[25px] w-[100%] !border-t-1"
                                          />
                                        </div>
                                      </div>

                                      <div className="w-full flex flex-row mt-1">
                                        <div className="td w-[20%] flex flex-row items-center">
                                          <input
                                            className="input-bg-color py-1 px-3 w-[100%] text-center mr-2"
                                            type="text"
                                            name="qty"
                                            value={item.qty ? item.qty : ""}
                                            placeholder="Cantidad"
                                            onChange={(e) => {
                                              let newItems = [...items];
                                              newItems.map((item, it) => {
                                                if (i == it) {
                                                  item.qty = e.target.value;
                                                  item.subtotal =
                                                    String(
                                                      item.price
                                                    ).replaceAll(",", "") *
                                                    item.qty;
                                                  item.subtotal =
                                                    numberWithCommas(
                                                      item.subtotal
                                                    );
                                                }
                                              });
                                              setItems(newItems);
                                            }}
                                          />
                                        </div>

                                        <div className="td w-[40%] flex flex-row items-center">
                                          <input
                                            className="input-bg-color py-1 px-3 w-[100%] text-center  mr-2"
                                            type="text"
                                            name="price"
                                            value={
                                              item.price
                                                ? numberWithCommas(item.price)
                                                : ""
                                            }
                                            placeholder="Precio"
                                            onChange={(e) => {
                                              let newItems = [...items];
                                              newItems.map((item, it) => {
                                                if (i == it) {
                                                  item.price = String(
                                                    e.target.value
                                                  ).replaceAll(",", "");
                                                  item.subtotal =
                                                    String(
                                                      item.price
                                                    ).replaceAll(",", "") *
                                                    item.qty;
                                                  item.subtotal =
                                                    numberWithCommas(
                                                      item.subtotal
                                                    );
                                                }
                                              });
                                              setItems(newItems);
                                            }}
                                          />
                                        </div>
                                        <div className="td w-[40%] flex flex-row items-center">
                                          <input
                                            className="input-bg-color py-1 px-3 w-[100%] text-center"
                                            type="text"
                                            readOnly
                                            name="subtotal"
                                            value={numberWithCommas(
                                              item.subtotal
                                            )}
                                          />
                                        </div>
                                      </div>

                                      <div className="h-[0%] w-full flex flex-row">
                                        <div className="h-[0%]">
                                          &nbsp;
                                          <input
                                            readOnly
                                            disabled
                                            className="inline-block input-bg-color py-1 px-3 w-[45%] text-center"
                                            type="hidden"
                                            name="iva"
                                            value={item.iva}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div> </div>
                                  )
                                )}
                              <button
                                className="w-full rounded-lg py-3 text-sm leading-5 primary-color text-xg ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 bg-green-200 primary-color hover:text-slate-500"
                                onClick={() => {
                                  setItera(itera + 1);
                                }}
                              >
                                Agregar
                              </button>
                            </div>
                            <div className="tr flex flex-row justify-between w-full">
                              <div className="flex flex-row justify-start items-center mr-0 mt-[1px]">
                                <div className="td text-left text-[14px] font-bold mr-1 ml-1 primary-color whitespace-nowrap">
                                  {width < 620 ? (
                                    <div></div>
                                  ) : (
                                    <div className="td text-left text-[14px] font-bold mr-12 primary-color whitespace-nowrap">
                                      COD. BARRAS
                                      <input
                                        className="input-bg-color appearance-none rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[25px] text-left w-[250px] focusbarcode"
                                        type="text"
                                        name="barcodesearch"
                                        value={barcodeSearch}
                                        onChange={(e) =>
                                          setBarcodeSearch(e.target.value)
                                        }
                                        onKeyDown={(e) => {
                                          //console.log(e.target.value);
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                            searchItems(
                                              e.target.value,
                                              "barcode"
                                            );
                                            setBarcodeSearch(
                                              (prev) => e.target.value
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="td text-right text-[14px] font-bold mr-1 primary-color whitespace-nowrap">
                                  CANT ITEMS:
                                </div>
                                <div className="td text-right text-[14px] font-bold mr-1 primary-color mr-19">
                                  {itemsQty}
                                </div>
                              </div>
                              <div
                                className="flex flex-col justify-end items-center mr-0 mt-[1px]"
                                style={{ marginRight: "29.5%" }}
                              >
                                <div className="flex flex-col">
                                  {/* Renderiza primero el valor de la moneda por defecto */}
                                  <div className="flex items-center">
                                    <div className="td w-[180px] text-right text-[14px] font-bold mr-1 primary-color ml-4 campototal">
                                      TOTAL {getCurrencyName(defaultCurrency)}:
                                    </div>
                                    <div className="td w-[180px] border-b-[1px] border-black pb-[2px] campototal2">
                                      <input
                                        className="!bg-transparent border-b-[1px] border-t-[1px] border-black !text-black py-1 px-3 w-full text-right font-bold text-[14px] "
                                        type="text"
                                        readOnly
                                        disabled
                                        name="total"
                                        value={numberWithCommas(
                                          (totalAmountSin / 1).toFixed(2)
                                        )}
                                        placeholder="Total"
                                      />
                                    </div>
                                  </div>

                                  {/* Renderiza los valores de las otras monedas */}
                                  {exchangeData.rates
                                    .filter(
                                      (currency) =>
                                        currency.id !==
                                        parseInt(defaultCurrency)
                                    )
                                    .map((currency, index) => (
                                      <div
                                        className="flex items-center"
                                        key={index}
                                      >
                                        <div className="td w-[180px] text-right text-[14px] font-bold mr-1 primary-color ml-4 campototal">
                                          TOTAL {currency.name}:
                                        </div>
                                        <div className="td w-[180px] border-b-[1px] border-black pb-[2px] campototal2">
                                          <input
                                            className="!bg-transparent border-b-[1px] border-t-[1px] border-black !text-black py-1 px-3 w-full text-right font-bold text-[14px] "
                                            type="text"
                                            readOnly
                                            disabled
                                            name="total"
                                            value={numberWithCommas(
                                              (
                                                totalAmountSin / currency.amount
                                              ).toFixed(2)
                                            )}
                                            placeholder="Total"
                                          />
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>

                          {saleConditionId == 2 && (
                            <div className="table !block sales-table !w-[98%] sm:w-[22%] ml-2 !h-[250px] overflow-hidden overflow-y-scroll relative pt-0 xl:pt-[23px] min-w-[240px] sm:mt-0 mt-3">
                              <div className="flex flex-row w-full xl:fixed block z-10 mt-0 xl:mt-[-23px]">
                                <label
                                  className="block text-sm font-bold text-center uppercase primary-color-bg !text-white !text-[11px] w-[33%] xl:w-[6.3%]"
                                  style={{ lineHeight: 2 }}
                                  htmlFor="number"
                                >
                                  NRO.
                                </label>
                                <label
                                  className="block text-sm font-bold text-center uppercase primary-color-bg !text-white !text-[11px] w-[33%] xl:w-[6.3%]"
                                  style={{ lineHeight: 2 }}
                                  htmlFor="expirationDate"
                                >
                                  VENC.
                                </label>
                                <label
                                  className="block text-sm font-bold text-center uppercase primary-color-bg !text-white !text-[11px] w-[33%] xl:w-[6.3%]"
                                  style={{ lineHeight: 2 }}
                                  htmlFor="amount"
                                >
                                  MONTO
                                </label>
                              </div>

                              {dues.length > 0 &&
                                dues.map((due, i) => (
                                  <>
                                    <div className="flex flex-row relative">
                                      <div
                                        className={`w-[20%] border-slate-200 border-b-2`}
                                      >
                                        <input
                                          className="w-[45px] input-bg-color appearance-none rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[28px] text-center"
                                          id="number"
                                          readOnly
                                          type="text"
                                          value={due.number}
                                        />
                                      </div>

                                      <div
                                        className={`border-slate-200 border-b-2`}
                                      >
                                        <input
                                          className="input-bg-color appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[28px]"
                                          id="expirationDate"
                                          type="date"
                                          value={due.expirationDate}
                                          onChange={(e) => {
                                            let newDues = [...dues];
                                            newDues.map((due, idx) => {
                                              if (idx == i) {
                                                due.expirationDate =
                                                  e.target.value;
                                              }
                                            });
                                            setDues(newDues);
                                          }}
                                        />
                                      </div>

                                      <div
                                        className={`border-slate-200 border-b-2`}
                                      >
                                        <input
                                          className="input-bg-color appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[28px] text-center"
                                          id="amount"
                                          type="text"
                                          value={numberWithCommas(due.amount)}
                                          onChange={(e) => {
                                            let newDues = [...dues];
                                            newDues.map((due, idx) => {
                                              if (idx == i) {
                                                due.amount = Number(
                                                  String(
                                                    e.target.value
                                                  ).replaceAll(",", "")
                                                );
                                              }
                                            });
                                            setDues(newDues);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </div>
                          )}
                          <div className="w-50 flex flex-wrap">
                            {saleConditionId == 1 && (
                              <div className="w-full p-4">
                                <div className="flex items-center justify-between space-x-2">
                                  <label className=" !text-[11px] font-bold uppercase whitespace-nowrap mb-1">
                                    {" "}
                                    COTIZACION:
                                  </label>
                                  <label className=" !text-[11px] font-bold uppercase whitespace-nowrap mb-1">
                                    {" "}
                                    RECIBIDO:
                                  </label>
                                  <label className=" !text-[11px] font-bold uppercase whitespace-nowrap mb-1">
                                    {" "}
                                    TOTAL/VUELTO:
                                  </label>
                                </div>
                                {exchangeData.rates.map((currency, index) => (
                                  <div
                                    key={index}
                                    className="flex items-center space-x-2"
                                  >
                                    <input
                                      id={`price-${index}`}
                                      type="text"
                                      className="input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight h-[22px] !text-[11px]"
                                      value={`${currency.name} - ${currency.amount}`}
                                      readOnly
                                    />
                                    <input
                                      id={`recibido-${index}`}
                                      type="text"
                                      className="input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight h-[22px] !text-[11px]"
                                      placeholder="Recibido"
                                      value={numberWithCommas(
                                        receivedValues[index]
                                      )}
                                      onChange={(e) =>
                                        handleReceivedChange(
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />

                                    <input
                                      id={`calculo-${index}`}
                                      type="text"
                                      className="input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight h-[22px] !text-[11px]"
                                      placeholder="Total Vuelto"
                                      value={numberWithCommas(
                                        restReceivedValues[index]
                                      )}
                                      readOnly
                                    />
                                  </div>
                                ))}

                                <div className="flex items-center space-x-2 mt-3 pl-[34.3%]">
                                  <div className="td w-[50%] m-[1px] flex flex-col items-start">
                                    <label className="block !text-[11px] font-bold uppercase whitespace-nowrap mb-1">
                                      TOTAL A COBRAR:
                                    </label>
                                    <input
                                      type="text"
                                      className="block input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight h-[22px] !text-[11px]"
                                      placeholder="Total a cobrar"
                                      value={numberWithCommas(totalAmountSin)}
                                      readOnly
                                    />
                                  </div>
                                  <div className="td w-[50%] m-[1px] flex flex-col items-start">
                                    <label className="block !text-[11px] font-bold uppercase whitespace-nowrap mb-1">
                                      SALDO A COBRAR:
                                    </label>
                                    <input
                                      type="text"
                                      className="input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight h-[22px] !text-[11px]"
                                      placeholder="Saldo a Cobrar"
                                      value={numberWithCommas(
                                        totalAmountChange
                                      )}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-2 py-2 justify-start flex relative top-3">
                <button
                  type="button"
                  className="float-left w-[65px] h-[63px] inline-flex flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 mr-2"
                  onClick={() => {
                    setItemsOpen(true);
                  }}
                >
                  <img src={product} className="w-8" />
                  ARTICULOS
                </button>
                <button
                  type="button"
                  className="float-left w-[65px] h-[63px] inline-flex flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 mr-2"
                  onClick={() => {
                    clearFields();
                  }}
                >
                  <img src={broom} className="w-8" />
                  LIMPIAR
                </button>
                <button
                  type="button"
                  className="float-left w-[65px] h-[63px] inline-flex flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 mr-2"
                  onClick={() => {
                    searchItems("DESCUENTO GENERAL", "modaldes");
                    setValueDesc(0)
                    setValueDescPor(0)
                    setItera(itera + 1)
                  }}
                >
                  <img src={DESC} className="w-8" />
                  DESCUENTO
                </button>
                <button
                  readOnly={action == "edit" ? true : false}
                  disabled={action == "edit" ? true : false}
                  type="button"
                  className="float-left inline-flex w-[65px] h-[63] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 mr-2"
                  style={{ alignItems: "center" }}
                  onClick={() => handleSubmit()}
                >
                  <img src={floppy} className="w-8" />
                  GUARDAR
                </button>

                <button
                  readOnly={action == "edit" ? true : false}
                  disabled={action == "edit" ? true : false}
                  type="button"
                  className="float-left inline-flex w-[65px] h-[63px] ml-[-0px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 mr-2"
                  style={{ alignItems: "center" }}
                  onClick={() => navigate("/")}
                >
                  <img src={door} className="w-5" />
                  SALIR
                </button>

              </div>
            </div>
          </div>
        </Transition.Root>
      </Layout>
    </>
  );
};

export default SalesCreate;
