import React, { useState, Fragment, useEffect } from "react";
import { PlusCircle } from "lucide-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Layout from "../layout";
import { Dialog, Transition, Tab } from "@headlessui/react";
import { format, isWithinInterval, parseISO } from "date-fns"; // Asegúrate de instalar date-fns: npm install date-fns

// Datos de ejemplo (Usuarios)
const usuarios = [
  { id: 1, nombre: "Cesar Amarilla" },
  { id: 2, nombre: "Willian Presentado" },
  { id: 3, nombre: "Jose Benitez" },
  { id: 4, nombre: "Marcelo Riveros" },
  { id: 5, nombre: "Alexis Gimenez" },
  { id: 6, nombre: "Heber Romero" },
  { id: 7, nombre: "Diego Maldonado" },
];

// Empresas (puedes mantener estas si también deseas cargarlas desde localStorage)
const empresas = [
  { id: 1, nombre: "Ocus S.A" },

];

// Funciones auxiliares para manejar localStorage
const cargarDesdeLocalStorage = (clave, valorInicial) => {
  const dato = localStorage.getItem(clave);
  if (dato) {
    try {
      return JSON.parse(dato);
    } catch (error) {
      console.error(`Error al parsear ${clave} desde localStorage:`, error);
      return valorInicial;
    }
  }
  return valorInicial;
};

const guardarEnLocalStorage = (clave, dato) => {
  localStorage.setItem(clave, JSON.stringify(dato));
};

const Backlogs = () => {
  // Estados para la creación de incidencias
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [nuevaIncidencia, setNuevaIncidencia] = useState({
    titulo: "",
    descripcion: "",
    estado: "Pendiente",
    prioridad: "Medio",
    usuarioAsignado: "",
    sprint: "",
    empresa: "",
    tipo: "Desarrollo",
  });

  // Estados para seleccionar y mostrar detalles de una incidencia
  const [incidenciaSeleccionada, setIncidenciaSeleccionada] = useState(null);

  // Estado para la selección de sprint
  const [sprintSeleccionado, setSprintSeleccionado] = useState(null);

  // Estado de los sprints (cargados desde localStorage o inicializados)
  const [sprints, setSprints] = useState(() =>
    cargarDesdeLocalStorage("sprints", [
      {
        id: null,
        nombre: "Sin asignar",
        startDate: null,
        endDate: null,
      },
    ])
  );

  // Estado de las incidencias (cargadas desde localStorage o inicializadas)
  const [incidencias, setIncidencias] = useState(() =>
    cargarDesdeLocalStorage("incidencias", [])
  );

  // Estado para la creación de nuevos sprints
  const [isCreateSprintOpen, setIsCreateSprintOpen] = useState(false);
  const [nuevoSprint, setNuevoSprint] = useState({
    nombre: "",
    startDate: "",
    endDate: "",
  });

  // Sincronizar sprints con localStorage cada vez que cambian
  useEffect(() => {
    guardarEnLocalStorage("sprints", sprints);
  }, [sprints]);

  // Sincronizar incidencias con localStorage cada vez que cambian
  useEffect(() => {
    guardarEnLocalStorage("incidencias", incidencias);
  }, [incidencias]);

  // Determinar el sprint actual basado en la fecha de hoy
  useEffect(() => {
    const hoy = new Date();
    const sprintActual = sprints.find((sprint) => {
      if (sprint.startDate && sprint.endDate) {
        return isWithinInterval(hoy, {
          start: parseISO(sprint.startDate),
          end: parseISO(sprint.endDate),
        });
      }
      return false;
    });

    setSprintSeleccionado(sprintActual || sprints[0]); // Si no hay sprint actual, selecciona "Sin asignar"
  }, [sprints]);

  // Función para crear una nueva incidencia
  const manejarCrearIncidencia = () => {
    if (nuevaIncidencia.titulo && nuevaIncidencia.descripcion) {
      const nuevoId =
        incidencias.length > 0
          ? Math.max(...incidencias.map((incidencia) => incidencia.id)) + 1
          : 1;
      const incidenciaAgregar = {
        ...nuevaIncidencia,
        id: nuevoId,
        usuarioAsignado:
          usuarios.find(
            (usuario) => usuario.id === parseInt(nuevaIncidencia.usuarioAsignado)
          ) || null,
        sprint:
          sprints.find((s) => s.id === parseInt(nuevaIncidencia.sprint)) || null,
        empresa:
          empresas.find((e) => e.id === parseInt(nuevaIncidencia.empresa)) || null,
      };
      setIncidencias([...incidencias, incidenciaAgregar]);
      setNuevaIncidencia({
        titulo: "",
        descripcion: "",
        estado: "Pendiente",
        prioridad: "Medio",
        usuarioAsignado: "",
        sprint: "",
        empresa: "",
        tipo: "Desarrollo",
      });
      setIsCreateOpen(false);
    } else {
      alert("Por favor, completa todos los campos requeridos.");
    }
  };

  // Función para asignar sprint a una incidencia
  const manejarAsignarSprint = (idIncidencia, idSprint) => {
    setIncidencias(
      incidencias.map((incidencia) =>
        incidencia.id === idIncidencia
          ? { ...incidencia, sprint: sprints.find((s) => s.id === idSprint) || null }
          : incidencia
      )
    );
  };

  // Función para manejar el evento de arrastrar y soltar
  const alFinalizarArrastre = (resultado) => {
    const { destination, source } = resultado;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const estadoOrigen = source.droppableId;
    const estadoDestino = destination.droppableId;

    // Obtener las incidencias en la lista de source y en el sprint seleccionado
    const incidenciasOrigen = incidencias.filter(
      (incidencia) =>
        incidencia.estado === estadoOrigen &&
        (sprintSeleccionado.id === null ||
          (incidencia.sprint && incidencia.sprint.id === sprintSeleccionado.id))
    );

    // Obtener la incidencia a mover
    const incidenciaAMover = incidenciasOrigen[source.index];

    if (!incidenciaAMover) {
      return;
    }

    // Actualizar el estado de la incidencia
    const incidenciaActualizada = { ...incidenciaAMover, estado: estadoDestino };

    // Eliminar la incidencia de la lista original
    const nuevasIncidencias = incidencias.filter(
      (incidencia) => incidencia.id !== incidenciaAMover.id
    );

    // Insertar la incidencia en la nueva posición
    const incidenciasDestino = nuevasIncidencias.filter(
      (incidencia) =>
        incidencia.estado === estadoDestino &&
        (sprintSeleccionado.id === null ||
          (incidencia.sprint && incidencia.sprint.id === sprintSeleccionado.id))
    );
    const antes = incidenciasDestino.slice(0, destination.index);
    const despues = incidenciasDestino.slice(destination.index);
    const nuevasIncidenciasDestino = [
      ...antes,
      incidenciaActualizada,
      ...despues,
    ];

    // Obtener las incidencias que no están en la lista de destination
    const incidenciasRestantes = nuevasIncidencias.filter(
      (incidencia) =>
        incidencia.estado !== estadoDestino ||
        (sprintSeleccionado.id !== null &&
          (!incidencia.sprint || incidencia.sprint.id !== sprintSeleccionado.id))
    );

    // Actualizar el estado de las incidencias
    setIncidencias([...incidenciasRestantes, ...nuevasIncidenciasDestino]);
  };

  // Función para crear un nuevo sprint
  const manejarCrearSprint = () => {
    const { nombre, startDate, endDate } = nuevoSprint;

    if (nombre && startDate && endDate) {
      // Validar que las fechas sean correctas
      const inicio = new Date(startDate);
      const fin = new Date(endDate);
      if (inicio > fin) {
        alert("La fecha de inicio debe ser anterior a la fecha de fin.");
        return;
      }

      // Validar que no haya solapamiento con otros sprints
      const solapado = sprints.some((sprint) => {
        if (sprint.startDate && sprint.endDate) {
          return (
            isWithinInterval(inicio, {
              start: parseISO(sprint.startDate),
              end: parseISO(sprint.endDate),
            }) ||
            isWithinInterval(fin, {
              start: parseISO(sprint.startDate),
              end: parseISO(sprint.endDate),
            }) ||
            (inicio <= parseISO(sprint.startDate) &&
              fin >= parseISO(sprint.endDate))
          );
        }
        return false;
      });

      if (solapado) {
        alert("Las fechas del sprint se solapan con otro sprint existente.");
        return;
      }

      const nuevoId =
        sprints.length > 0 ? Math.max(...sprints.map((s) => s.id || 0)) + 1 : 1;
      const sprintAgregar = {
        id: nuevoId,
        nombre,
        startDate,
        endDate,
      };
      setSprints([...sprints, sprintAgregar]);
      setNuevoSprint({
        nombre: "",
        startDate: "",
        endDate: "",
      });
      setIsCreateSprintOpen(false);
    } else {
      alert("Por favor, completa todos los campos requeridos para el sprint.");
    }
  };

  // Componente para mostrar una incidencia
  const TarjetaIncidencia = ({ incidencia }) => (
    <div
      className="mb-4 p-4 border rounded-lg cursor-pointer bg-white shadow hover:shadow-md transition-shadow"
      onClick={() => setIncidenciaSeleccionada(incidencia)}
    >
      <div className="flex justify-between items-center">
        <h3 className="font-semibold text-lg">{incidencia.titulo}</h3>
        <span
          className={`text-xs px-2 py-1 rounded-full ${incidencia.prioridad === "Alto"
              ? "bg-red-100 text-red-800"
              : incidencia.prioridad === "Medio"
                ? "bg-yellow-100 text-yellow-800"
                : "bg-green-100 text-green-800"
            }`}
        >
          {incidencia.prioridad}
        </span>
      </div>
      <p className="text-sm text-gray-500 mt-1">{incidencia.descripcion}</p>
      <div className="flex flex-wrap gap-2 mt-2">
        <span className="text-xs px-2 py-1 rounded-full bg-blue-100 text-blue-800">
          {incidencia.estado}
        </span>
        <span className="text-xs px-2 py-1 rounded-full bg-purple-100 text-purple-800">
          {incidencia.tipo}
        </span>
        {incidencia.usuarioAsignado && (
          <span className="text-xs px-2 py-1 rounded-full bg-green-100 text-green-800">
            Asignado a: {incidencia.usuarioAsignado.nombre}
          </span>
        )}
      </div>
    </div>
  );

  return (
    <Layout>
      <div className="p-6 bg-gray-100 min-h-screen">
        {/* Tabs utilizando Headless UI */}
        <Tab.Group>
          <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
            <Tab
              className={({ selected }) =>
                `w-full py-2.5 text-sm leading-5 font-medium text-gray-700 rounded-lg
                focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60
                ${selected
                  ? "bg-gray shadow text-gray-700 "
                  : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                }`
              }
            >
              Backlogs
            </Tab>
            <Tab
              className={({ selected }) =>
                `w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg
                focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60
                ${selected
                  ? "bg-gray shadow text-gray-700"
                  : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                }`
              }
            >
              Sprints
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-2">
            {/* Backlogs Tab */}
            <Tab.Panel className="bg-white p-4 rounded-xl shadow-md">
              <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold">Backlogs</h1>
                <button
                  className="flex items-center bg-teal-500 text-white px-4 py-2 rounded-md hover:bg-teal-600 transition-colors"
                  onClick={() => setIsCreateOpen(true)}
                >
                  <PlusCircle className="mr-2 h-4 w-4" />
                  Crear Incidencia
                </button>
              </div>

              {/* Modal para Crear Incidencia */}
              <Transition.Root show={isCreateOpen} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-10"
                  onClose={() => setIsCreateOpen(false)}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900 mb-4"
                          >
                            Crear Nueva Incidencia
                          </Dialog.Title>
                          <form className="space-y-4">
                            <div>
                              <label
                                htmlFor="titulo"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Título
                              </label>
                              <input
                                type="text"
                                id="titulo"
                                placeholder="Título de la incidencia"
                                value={nuevaIncidencia.titulo}
                                onChange={(e) =>
                                  setNuevaIncidencia({
                                    ...nuevaIncidencia,
                                    titulo: e.target.value,
                                  })
                                }
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-teal-500 focus:border-teal-500"
                                required
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="descripcion"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Descripción
                              </label>
                              <textarea
                                id="descripcion"
                                placeholder="Descripción de la incidencia"
                                value={nuevaIncidencia.descripcion}
                                onChange={(e) =>
                                  setNuevaIncidencia({
                                    ...nuevaIncidencia,
                                    descripcion: e.target.value,
                                  })
                                }
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-teal-500 focus:border-teal-500"
                                required
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="prioridad"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Prioridad
                              </label>
                              <select
                                id="prioridad"
                                value={nuevaIncidencia.prioridad}
                                onChange={(e) =>
                                  setNuevaIncidencia({
                                    ...nuevaIncidencia,
                                    prioridad: e.target.value,
                                  })
                                }
                                className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm p-2 focus:ring-teal-500 focus:border-teal-500"
                              >
                                <option value="Baja">Baja</option>
                                <option value="Medio">Media</option>
                                <option value="Alto">Alta</option>
                              </select>
                            </div>
                            <div>
                              <label
                                htmlFor="tipo"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Tipo de Incidencia
                              </label>
                              <select
                                id="tipo"
                                value={nuevaIncidencia.tipo}
                                onChange={(e) =>
                                  setNuevaIncidencia({
                                    ...nuevaIncidencia,
                                    tipo: e.target.value,
                                  })
                                }
                                className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm p-2 focus:ring-teal-500 focus:border-teal-500"
                              >
                                <option value="Bug Fixing">Bug Fixing</option>
                                <option value="Desarrollo">Desarrollo</option>
                                <option value="Soporte">Soporte</option>
                                <option value="Documentation">Documentation</option>
                                <option value="Other">Other</option>
                              </select>
                            </div>
                            <div>
                              <label
                                htmlFor="usuarioAsignado"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Usuario Asignado
                              </label>
                              <select
                                id="usuarioAsignado"
                                value={nuevaIncidencia.usuarioAsignado}
                                onChange={(e) =>
                                  setNuevaIncidencia({
                                    ...nuevaIncidencia,
                                    usuarioAsignado: e.target.value,
                                  })
                                }
                                className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm p-2 focus:ring-teal-500 focus:border-teal-500"
                              >
                                <option value="">Seleccionar usuario</option>
                                {usuarios.map((usuario) => (
                                  <option key={usuario.id} value={usuario.id}>
                                    {usuario.nombre}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label
                                htmlFor="empresa"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Empresa
                              </label>
                              <select
                                id="empresa"
                                value={nuevaIncidencia.empresa}
                                onChange={(e) =>
                                  setNuevaIncidencia({
                                    ...nuevaIncidencia,
                                    empresa: e.target.value,
                                  })
                                }
                                className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm p-2 focus:ring-teal-500 focus:border-teal-500"
                              >
                                <option value="">Seleccionar empresa</option>
                                {empresas.map((empresa) => (
                                  <option key={empresa.id} value={empresa.id}>
                                    {empresa.nombre}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label
                                htmlFor="sprint"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Sprint
                              </label>
                              <select
                                id="sprint"
                                value={nuevaIncidencia.sprint}
                                onChange={(e) =>
                                  setNuevaIncidencia({
                                    ...nuevaIncidencia,
                                    sprint: e.target.value,
                                  })
                                }
                                className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm p-2 focus:ring-teal-500 focus:border-teal-500"
                              >
                                <option value="">Seleccionar sprint</option>
                                {sprints.map((sprint) => (
                                  <option
                                    key={sprint.id || "null"}
                                    value={sprint.id || ""}
                                  >
                                    {sprint.nombre}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="flex justify-end space-x-2">
                              <button
                                type="button"
                                onClick={manejarCrearIncidencia}
                                className="bg-teal-500 text-white px-4 py-2 rounded-md hover:bg-teal-600 transition-colors"
                              >
                                Guardar
                              </button>
                              <button
                                type="button"
                                onClick={() => setIsCreateOpen(false)}
                                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition-colors"
                              >
                                Cancelar
                              </button>
                            </div>
                          </form>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                    </div>
                </Dialog>
              </Transition.Root>

              {/* Lista de Incidencias sin Sprint Asignado */}
              <div className="bg-white p-4 rounded-lg shadow-md">
                <h2 className="text-xl font-semibold mb-4">
                  Incidencias sin sprint asignado
                </h2>
                <div className="max-h-[600px] overflow-y-auto">
                  {incidencias
                    .filter((incidencia) => incidencia.sprint === null)
                    .map((incidencia) => (
                      <TarjetaIncidencia key={incidencia.id} incidencia={incidencia} />
                    ))}
                </div>
              </div>
            </Tab.Panel>

            {/* Sprints Tab */}
            <Tab.Panel className="bg-white p-4 rounded-xl shadow-md">
              <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold">Sprints</h1>
                <button
                  className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
                  onClick={() => setIsCreateSprintOpen(true)}
                >
                  <PlusCircle className="mr-2 h-4 w-4" />
                  Crear Sprint
                </button>
              </div>

              {/* Modal para Crear Sprint */}
              <Transition.Root show={isCreateSprintOpen} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-10"
                  onClose={() => setIsCreateSprintOpen(false)}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900 mb-4"
                          >
                            Crear Nuevo Sprint
                          </Dialog.Title>
                          <form className="space-y-4">
                            <div>
                              <label
                                htmlFor="nombreSprint"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Nombre del Sprint
                              </label>
                              <input
                                type="text"
                                id="nombreSprint"
                                placeholder="Nombre del sprint"
                                value={nuevoSprint.nombre}
                                onChange={(e) =>
                                  setNuevoSprint({
                                    ...nuevoSprint,
                                    nombre: e.target.value,
                                  })
                                }
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                                required
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="startDate"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Fecha de Inicio
                              </label>
                              <input
                                type="date"
                                id="startDate"
                                value={nuevoSprint.startDate}
                                onChange={(e) =>
                                  setNuevoSprint({
                                    ...nuevoSprint,
                                    startDate: e.target.value,
                                  })
                                }
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                                required
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="endDate"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Fecha de Fin
                              </label>
                              <input
                                type="date"
                                id="endDate"
                                value={nuevoSprint.endDate}
                                onChange={(e) =>
                                  setNuevoSprint({
                                    ...nuevoSprint,
                                    endDate: e.target.value,
                                  })
                                }
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                                required
                              />
                            </div>
                            <div className="flex justify-end space-x-2">
                              <button
                                type="button"
                                onClick={manejarCrearSprint}
                                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
                              >
                                Crear
                              </button>
                              <button
                                type="button"
                                onClick={() => setIsCreateSprintOpen(false)}
                                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition-colors"
                              >
                                Cancelar
                              </button>
                            </div>
                          </form>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>

              <div className="mb-6">
                <label
                  htmlFor="seleccionarSprint"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Seleccionar Sprint
                </label>
                <select
                  id="seleccionarSprint"
                  value={sprintSeleccionado?.id?.toString() || "null"}
                  onChange={(e) => {
                    const idSprint =
                      e.target.value === "null" ? null : parseInt(e.target.value);
                    const sprint =
                      sprints.find((s) => s.id === idSprint) || sprints[0];
                    setSprintSeleccionado(sprint);
                  }}
                  className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm p-2 focus:ring-teal-500 focus:border-teal-500"
                >
                  <option value="null">Sin sprint</option>
                  {sprints
                    .filter((sprint) => sprint.id !== null)
                    .map((sprint) => (
                      <option key={sprint.id} value={sprint.id}>
                        {sprint.nombre} (
                        {sprint.startDate
                          ? format(parseISO(sprint.startDate), "dd/MM/yyyy")
                          : "N/A"}{" "}
                        -{" "}
                        {sprint.endDate
                          ? format(parseISO(sprint.endDate), "dd/MM/yyyy")
                          : "N/A"}
                        )
                      </option>
                    ))}
                </select>
              </div>

              {/* Área de Arrastrar y Soltar */}
              <DragDropContext onDragEnd={alFinalizarArrastre}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {["Pendiente", "En progreso", "Completado"].map((estado) => (
                    <Droppable key={estado} droppableId={estado}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="bg-gray-50 p-4 rounded-lg shadow-sm min-h-[400px]"
                        >
                          <h2 className="text-lg font-semibold mb-4 text-center">
                            {estado}
                          </h2>
                          {incidencias
                            .filter(
                              (incidencia) =>
                                incidencia.sprint?.id === sprintSeleccionado.id &&
                                incidencia.estado === estado
                            )
                            .map((incidencia, index) => (
                              <Draggable
                                key={incidencia.id}
                                draggableId={incidencia.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="mb-4"
                                  >
                                    <TarjetaIncidencia incidencia={incidencia} />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  ))}
                </div>
              </DragDropContext>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

        {/* Modal para Ver y Asignar Sprint a una Incidencia */}
        <Transition.Root show={!!incidenciaSeleccionada} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => setIncidenciaSeleccionada(null)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 ">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-bold mb-4"
                    >
                      {incidenciaSeleccionada?.titulo}
                    </Dialog.Title>
                    <Dialog.Description className="mb-4">
                      {incidenciaSeleccionada?.descripcion}
                    </Dialog.Description>
                    <div className="mb-4">
                      <span
                        className={`text-xs px-2 py-1 rounded-full ${incidenciaSeleccionada?.prioridad === "Alto"
                            ? "bg-red-100 text-red-800"
                            : incidenciaSeleccionada?.prioridad === "Medio"
                              ? "bg-yellow-100 text-yellow-800"
                              : "bg-green-100 text-green-800"
                          }`}
                      >
                        Prioridad: {incidenciaSeleccionada?.prioridad}
                      </span>
                      <span className="text-xs px-2 py-1 rounded-full bg-blue-100 text-blue-800 ml-2">
                        Estado: {incidenciaSeleccionada?.estado}
                      </span>
                    </div>
                    <div className="mb-4">
                      <span className="text-xs px-2 py-1 rounded-full bg-purple-100 text-purple-800">
                        Tipo: {incidenciaSeleccionada?.tipo}
                      </span>
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="asignarSprint"
                        className="block text-sm font-medium text-gray-700 mb-2"
                      >
                        Asignar a Sprint
                      </label>
                      <select
                        id="asignarSprint"
                        value={
                          incidenciaSeleccionada?.sprint?.id?.toString() || "null"
                        }
                        onChange={(e) => {
                          const idSprint =
                            e.target.value === "null"
                              ? null
                              : parseInt(e.target.value);
                          manejarAsignarSprint(
                            incidenciaSeleccionada.id,
                            idSprint
                          );
                          setIncidenciaSeleccionada(null);
                        }}
                        className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm p-2 focus:ring-teal-500 focus:border-teal-500"
                      >
                        <option value="null">Sin sprint</option>
                        {sprints
                          .filter((sprint) => sprint.id !== null)
                          .map((sprint) => (
                            <option key={sprint.id} value={sprint.id}>
                              {sprint.nombre} (
                              {sprint.startDate
                                ? format(
                                  parseISO(sprint.startDate),
                                  "dd/MM/yyyy"
                                )
                                : "N/A"}{" "}
                              -{" "}
                              {sprint.endDate
                                ? format(parseISO(sprint.endDate), "dd/MM/yyyy")
                                : "N/A"}
                              )
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="flex justify-end space-x-2">
                      <button
                        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
                        onClick={() => setIncidenciaSeleccionada(null)}
                      >
                        Cerrar
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </Layout>
  );
};

export default Backlogs;
