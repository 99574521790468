import React, { useState, useEffect, Fragment, useRef } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DateComponent from "../components/date";
import ComboBox from "../components/combobox";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";
import cash from "../assets/img/cash.png";
import { numberWithCommas } from "../utils/constants";
import useViewport from "../utils/useViewport";
import broom from "../assets/img/broom.png";
import toast from "react-hot-toast";

const ProviderPayments = () => {
  const user = useSelector((state) => state.main.user);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const { page } = useParams();
  const { width } = useViewport();

  const [buttonVisible, setButtonVisible] = useState(true);
  const [paymentsOpen, setPaymentsOpen] = useState(false);
  const [checksOpen, setChecksOpen] = useState(false);
  const [checksOpenB, setChecksOpenB] = useState(false);
  const [transferOpen, setTransferOpen] = useState(false);
  const [transferOpenB, setTransferOpenB] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const [date, setDate] = useState(new Date().toLocaleDateString());

  const [checks, setChecks] = useState([
    {
      bankId: 0,
      currencyId: 0,
      name: "",
      amount: 0,
      issueDate: "",
      expirationDate: "",
    },
  ]);
  const [transfers, setTransfers] = useState([
    { accountId: 0, account: {}, amount: 0 },
  ]);
  const [accountId, setAccountId] = useState([]);
  const [account, setAccount] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [banks, setBanks] = useState([]);
  const [purchaseDetail, setPurchaseDetail] = useState([]);
  const [due, setDue] = useState([]);
  const [dues, setDues] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [cleanPaymentMethods, setCleanPaymentMethods] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [providers, setProviders] = useState([]);
  const [provider, setProvider] = useState({});
  const [providerId, setProviderId] = useState(0);
  const [sellers, setSellers] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [boxId, setBoxId] = useState(user.box.id);
  const [sellerId, setSellerId] = useState(user.id);
  const [currencyId, setCurrencyId] = useState(1);
  const [branchId, setBranchId] = useState(user.branch.id);
  const [purchaseId, setPurchaseId] = useState(0);
  const [purchaseNumber, setPurchaseNumber] = useState(0);
  const [paymentId, setPaymentId] = useState(0);
  const [paymentOrderId, setPaymentOrderId] = useState(0);
  const [endTotal, setEndTotal] = useState(0);
  const [endTotalReceived, setEndTotalReceived] = useState(0);
  const [expeditionPoint, setExpeditionPoint] = useState();
  const [number, setNumber] = useState();
  const [endTotalRest, setEndTotalRest] = useState(0);
  const [amountToCharge, setAmountToCharge] = useState(0);
  const [amountToPay, setAmountToPay] = useState(0);
  const [detail, setDetail] = useState("");

  async function fetchPaymentsData() {
    let pageQuery = page == undefined ? 1 : page;

    setIsFetching(true);

    let data = await axiosAuth.get("/providerpayments?page=" + pageQuery);

    if (data.data.error) {
      if (data.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    data.data.paymentmethods.map((paymentmethod) => {
      // paymentmethod.toPay = false;
      if (paymentmethod.id == 1 || paymentmethod.id == 4) {
        paymentmethod.amount = 0;
      }
      if (paymentmethod.id == 2) {
        paymentmethod.checks = [
          {
            bankId: 0,
            currencyId: 0,
            name: "",
            amount: 0,
            issueDate: "",
            expirationDate: "",
          },
        ];
      }
      if (paymentmethod.id == 3) {
        paymentmethod.transfers = [{ accountId: 0, account: {}, amount: 0 }];
      }
    });

    //setProviders(data.data.providers.rows);

    setPaymentMethods(data.data.paymentmethods);
    setCleanPaymentMethods(data.data.paymentmethods);
    setCurrencies(data.data.currencies);
    setAccounts(data.data.accounts);
    setBanks(data.data.banks);
    setSellers(data.data.sellers.rows);
    setBoxes(data.data.boxes);
    setNumber(data.data.boxes[0].receiptNumber + 1);
  }

  useEffect(() => {
    //setDues([]);
    setProvider(
      (prev) =>
        providers.filter((provider) => {
          if (provider.id === providerId) {
            provider?.purchases?.map((providerPurchase) => {
              // clientSale.dues.map((due) => {
              //   due.buttonVisible = true;
              // });
              //providerPurchase.selected = false;
            });
            setPurchases(provider?.purchases);
            return true;
          }
        })[0]
    );
  }, [providerId]);

  useEffect(() => {
    let newDate = date
      .split("/")
      .map((val) => {
        if (val.length <= 1) {
          return "0" + val;
        } else {
          return val;
        }
      })
      .join("/");

    setDate(newDate);

    fetchPaymentsData();

    if (state != null) {
      setPaymentOrderId((prev) => state.paymentOrderId);

      const fetchProviderData = async () => {
        let paymentOrdeRequest = await axiosAuth.get(
          "/paymentorders/" + state.paymentOrderId
        );
        let providerRequest = await axiosAuth.get(
          "/providerpayments/" + paymentOrdeRequest.data.paymentOrder.providerId
        );

        let newProviders = [...providers];
        providerRequest.data.providerPayment.purchases.map((purchase, p) => {
          purchase?.dues.map((due) => {
            paymentOrdeRequest.data.paymentOrder.paymentorderdetails.map(
              (det) => {
                if (det.dueId == due.id) {
                  due.paying = det.paying;
                }
              }
            );
          });
        });
        providerRequest.data.providerPayment.purchases[0].selected = true;
        newProviders.push(providerRequest.data.providerPayment);
        setProviders((prev) => newProviders);
        setProviderId((prev) => providerRequest.data.providerPayment.id);
        setPurchases((prev) => providerRequest.data.providerPayment.purchases);
        setPurchaseId(
          (prev) => providerRequest.data.providerPayment.purchases[0].id
        );
        setPurchaseDetail(
          (prev) =>
            providerRequest.data.providerPayment.purchases[0].purchasesdetails
        );
        providerRequest.data.providerPayment.purchases[0].dues?.map(due => due.interests = '');
        setDues(
          (prev) => providerRequest.data.providerPayment.purchases[0].dues
        );
        setPurchaseNumber(
          (prev) =>
            providerRequest.data.providerPayment.purchases[0].expeditionPoint +
            " " +
            providerRequest.data.providerPayment.purchases[0].number
        );
      };
      fetchProviderData();
    }
  }, []);

  useEffect(() => {
    let newdetail = "";
    if (purchaseDetail?.length > 0 && typeof purchaseDetail != "string") {
      purchaseDetail?.map((det) => {
        newdetail += det.item.name + ", ";
      });
    }
    setPurchaseDetail(newdetail);
  }, [purchaseDetail]);

  useEffect(() => {
    let toEndTotal = 0;
    let toEndTotalReceived = 0;
    let toAmountToCharge = 0;
    let toAmountToPay = 0;

    purchases?.map((purchase) => {
      purchase?.purchasesdetails?.map((purchasedetail) => {
        toEndTotal += purchasedetail?.price * purchasedetail?.quantity;
      });

      purchase?.paymentdetails?.map((det) => {
        toEndTotalReceived += det?.amount;
      });

      purchase?.dues?.map((due) => {
        due.paying = due?.paying || 0;
        toAmountToCharge += parseFloat(String(due?.paying).replaceAll(",", "")) || 0;
        toAmountToCharge += parseFloat(String(due?.interests).replaceAll(",", "")) || 0;
        toEndTotalReceived += parseFloat(String(due?.paying).replaceAll(",", "")) || 0;
        toEndTotalReceived += parseFloat(String(due?.interests).replaceAll(",", "")) || 0;
      });
    });

    paymentMethods.map((paymentMethod) => {
      toAmountToPay +=
        paymentMethod?.amount != undefined && parseFloat(String(paymentMethod?.amount).replaceAll(",", "")) != "undefined"
          ? parseFloat(paymentMethod?.amount)
          : 0;
    });

    setEndTotal(toEndTotal);
    setEndTotalReceived(toEndTotalReceived);
    setEndTotalRest(toEndTotal - toEndTotalReceived);
    setAmountToCharge(toAmountToCharge);
    setAmountToPay(toAmountToPay);
  }, [purchases, paymentMethods, dues]);

  const clearFields = () => {
    setProviderId(0);
    setProvider([]);
    setDues([]);
    setPurchases([]);
    setPurchaseDetail([]);
    setEndTotal(0);
    setEndTotalReceived(0);
    setEndTotalRest(0);
    setAmountToCharge(0);
    setAmountToPay(0);
    setPaymentMethods(cleanPaymentMethods);
  };

  const handlePaymentOrderSubmit = async () => {
    let paymentOrder = { providerId, sellerId, currencyId, branchId };
    let paymentOrderDetails = [];

    purchases.map((purchase) => {
      purchase.dues.map((due) => {
        if (due.paying > 0) {
          paymentOrderDetails.push({
            purchaseId: purchase.id,
            dueId: due.id,
            paying: due.paying,
          });
        }
      });
    });

    await axiosAuth.post("/paymentorders", {
      paymentOrder,
      paymentOrderDetails,
    });

    toast.success("Orden de pago creada con exito.");
  };

  const handleSubmit = async () => {
    if (expeditionPoint == "" || number == "") {
      alert("Debe completar todos los campos para continuar!");
    }

    if (purchases.length <= 0) {
      alert("Debe cargar montos para poder pagarlos");
      return false;
    }

    let duesToSend = [];
    let paymentsToSend = [];

    purchases.map((purchase) => {
      purchase.dues.map((due) => {
        // if (due.toPay) {
        if (due.paying) {
          due.paid = parseFloat(due.paid) + parseFloat(due.paying);
          duesToSend.push(due);
        }
      });
    });

    paymentMethods.map((payment) => {
      if (payment.amount > 0) {
        payment.purchaseId = purchaseId;
        paymentsToSend.push(payment);
      }
    });

    let objToSend = {
      paymentOrderId,
      purchaseId,
      providerId,
      branchId,
      boxId,
      sellerId,
      currencyId,
      number,
      expeditionPoint,
      date,
      amount: Number(String(amountToCharge).replaceAll(",", "")),
      dues: duesToSend,
      payments: paymentMethods,
    };

    let createdCollectionRequest = await axiosAuth.post(
      "/providerpayments",
      objToSend
    );

    if (createdCollectionRequest.data.error) {
      if (createdCollectionRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    clearFields();
    fetchPaymentsData();

    // if (window.confirm("Desea generar un PDF del recibo de pago?")) {
    //   window.open("/pagosrecibo/" + createdCollectionRequest.data);
    // }
  };

  const searchProviders = async (search) => {
    const searchProvidersRequest = await axiosAuth.get(
      "/searchProvidersPayments?search=" + search
    );

    setDues([]);
    setProviders(searchProvidersRequest.data.providers.rows);
  };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <>
      <Layout>
        <Transition.Root show={checksOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative"
            onClose={() => {
              setChecksOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-0 bg-white text-center 
                  shadow-xl transition-all sm:my-8 min-w-[380px]">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center w-full font-regular leading-6 primary-color text-2xl"
                          >
                            Cheques
                          </Dialog.Title>
                          <div className="mt-2">
                            <div className="w-[32rem]">
                              {checks.map((check, c) => (
                                <div className="sales-table text-[11px] w-full">
                              
                                  <div className="tbody">
                                    <>
                                      <div className="tr flex flex-col w-full m-1">
                                        <div className="td">
                                          <select
                                            className="input-bg-color appearance-none
                                             border w-full py-2 px-3 text-gray-700 leading-tight 
                                             focus:outline-none focus:shadow-outline h-[1.85rem] m-[2px]"
                                            style={{ lineHeight: 0.85 }}
                                            name="bank"
                                            placeholder="Banco"
                                            value={check.bankId}
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, nc) => {
                                                if (c == nc) {
                                                  newCheck.bankId =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                          >
                                            <option value={0}>
                                              Elija un banco
                                            </option>
                                            {banks.map((bank) => (
                                              <option value={bank.id}>
                                                {bank.name}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        <div className="td">
                                          <input
                                            className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6 m-[2px]"
                                            name="name"
                                            placeholder="Nombre"
                                            type="text"
                                            value={check.name}
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, nc) => {
                                                if (c == nc) {
                                                  newCheck.name =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                          />
                                        </div>
                                        <div className="td">
                                          <input
                                            className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6 m-[2px]"
                                            name="issueDate"
                                            placeholder="Fecha de Emision"
                                            type="date"
                                            value={check.issueDate.substring(
                                              0,
                                              10
                                            )}
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, nc) => {
                                                if (c == nc) {
                                                  newCheck.issueDate =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                          />
                                        </div>
                                        <div className="td">
                                          <input
                                            className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6 m-[2px]"
                                            name="expirationDate"
                                            placeholder="Fecha de Vencimiento"
                                            type="date"
                                            value={check.expirationDate.substring(
                                              0,
                                              10
                                            )}
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, nc) => {
                                                if (c == nc) {
                                                  newCheck.expirationDate =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                          />
                                        </div>
                                        <div className="td">
                                          <input
                                            type="text"
                                            className="input-bg-color appearance-none border w-full 
                                            py-2 px-3 text-gray-700 leading-tight focus:outline-none 
                                            focus:shadow-outline h-6 m-[2px]"
                                            name="amount"
                                            placeholder="Monto"
                                            value={numberWithCommas(
                                              check.amount
                                            )}
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, nc) => {
                                                if (c == nc) {
                                                  newCheck.amount = Number(
                                                    String(
                                                      e.target.value
                                                    ).replaceAll(",", "")
                                                  );
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                          />
                                        </div>
                                        <div className="td ">
                                          <select
                                            className="input-bg-color appearance-none border w-full
                                             py-2 px-3 text-gray-700 leading-tight focus:outline-none 
                                             focus:shadow-outline h-[1.90rem] m-[2px]"
                                            style={{ lineHeight: 0.85 }}
                                            name="currency"
                                            placeholder="Moneda"
                                            value={check.currencyId}
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, nc) => {
                                                if (c == nc) {
                                                  newCheck.currencyId =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                          >
                                            <option value={0}>
                                              Elija una moneda
                                            </option>
                                            {currencies.map((currency) => (
                                              <option value={currency.id}>
                                                {currency.name}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                      
                                    </>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    
                    
                    <div className=" px-4 py-3 justify-start flex  sm:w-full">
                    <button
                        className="inline-flex w-full rounded-0 font-family-thin  
                        justify-center px-3 py-2 text-sm font-semibold  shadow-sm ring-7 
                        ring-inset ring-gray-100 hover:bg-gray-10 sm:mt-0 sm:w-full font-family-thin"
                        onClick={() => {
                          let newChecks = [...checks];
                          newChecks.push({
                            bankId: 0,
                            currencyId: 0,
                            name: "",
                            amount: 0,
                            issueDate: "",
                            expirationDate: "",
                          });
                          setChecks(newChecks);
                        }}
                      >
                        AGREGAR
                      </button>
                      <button
                        type="button"
                        className="inline-flex w-full rounded-0 font-family-thin
                         ternary-bg-color justify-center px-3 py-2 text-sm font-semibold 
                         text-black shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-gray-200
                         sm:mt-0 sm:w-full font-family-thin"
                        onClick={() => {
                          setChecksOpen(false);
                        }}
                      >
                        CANCELAR
                      </button>
                      <button
                        type="button"
                        className="inline-flex w-full rounded-0 font-family-thin
                         ternary-bg-color justify-center px-3 py-2 text-sm font-semibold 
                         text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 
                         sm:mt-0 sm:w-full font-family-thin"
                        
                        onClick={() => {
                          let newPaymentMethods = [...paymentMethods];

                          newPaymentMethods.map((newPaymentMethod) => {
                            if (newPaymentMethod.id == 2) {
                              let toChecksTotal = 0;
                              checks.map((chk) => {
                                toChecksTotal += chk.amount;
                              });
                              newPaymentMethod.checks = checks;
                              newPaymentMethod.amount = toChecksTotal;
                            }
                          });

                          setPaymentMethods(newPaymentMethods);
                          setChecksOpen(false);
                        }}
                      >
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={transferOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative"
            onClose={() => {
              setTransferOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-0 bg-white text-center shadow-xl transition-all sm:my-8 w-[340px]">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center w-full font-regular leading-6 primary-color text-2xl"
                          >
                            Transferencias
                          </Dialog.Title>

                          <div className="mt-2">
                            <div className="w-full max-w-xs">
                              <input type="hidden" />
                              {transfers.map((transfer, t) => (
                                <>
                                  <input
                                    type="text"
                                    name="transferAmount"
                                    placeholder="Monto"
                                    value={numberWithCommas(transfer?.amount)}
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[30px] text-center m-[2px]"
                                    onChange={(e) => {
                                      let newTransfers = [...transfers];
                                      newTransfers.map((newTransfer, nt) => {
                                        if (t == nt) {
                                          newTransfer.amount = Number(
                                            String(e.target.value).replaceAll(
                                              ",",
                                              ""
                                            )
                                          );
                                        }
                                      });
                                      setTransfers(newTransfers);
                                    }}
                                  />

                                  <select
                                    name="accountId"
                                    value={transfer.accountId}
                                    onChange={(e) => {
                                      let newTransfers = [...transfers];
                                      newTransfers.map((newTransfer, nt) => {
                                        if (t == nt) {
                                          newTransfer.accountId =
                                            e.target.value;
                                          newTransfer.account = accounts.filter(
                                            (acc) => acc.id == e.target.value
                                          )[0];
                                        }
                                      });
                                      setTransfers(newTransfers);
                                    }}
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                  >
                                    <option value={0}>Elija una cuenta</option>
                                    {accounts.map((account) => (
                                      <option value={account.id}>
                                        {account.name}
                                      </option>
                                    ))}
                                  </select>

                                  <input
                                    readOnly
                                    disabled
                                    placeholder="Nro. de Cuenta"
                                    name="accountNumber"
                                    value={transfer?.account?.number}
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                  />

                                  <input
                                    readOnly
                                    disabled
                                    placeholder="Propietario"
                                    name="accountOwner"
                                    value={transfer?.account?.owner}
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                  />

                                  <input
                                    readOnly
                                    disabled
                                    placeholder="Banco"
                                    name="accountBank"
                                    value={transfer?.account?.bank?.name}
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                  />

                                  <input
                                    readOnly
                                    disabled
                                    placeholder="Tipo de cuenta"
                                    name="accounType"
                                    value={
                                      transfer?.account?.accountype?.description
                                    }
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                  />

                                  <input
                                    readOnly
                                    disabled
                                    placeholder="Moneda"
                                    name="accountCurrency"
                                    value={transfer?.account?.currency?.name}
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                  />
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        className="inline-flex w-full rounded-3xl font-family-thin secondary-bg-color justify-center px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto font-family-thin"
                        onClick={() => {
                          let newTransfers = [...transfers];
                          newTransfers.push({
                            accountId: 0,
                            account: {},
                            amount: 0,
                          });
                          setTransfers(newTransfers);
                        }}
                      >
                        Agregar transferencia
                      </button>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full rounded-3xl font-family-thin secondary-bg-color justify-center px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto font-family-thin"
                        onClick={() => {
                          setTransferOpen(false);
                        }}
                      >
                        CANCELAR
                      </button>
                      <button
                        type="button"
                        className="inline-flex w-full align-center rounded-3xl align-items-center justify-center primary-color-bg px-3 py-2 text-sm font-semibold  shadow-sm sm:ml-3 sm:w-auto font-family-thin"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          let newPaymentMethods = [...paymentMethods];

                          newPaymentMethods.map((newPaymentMethod) => {
                            if (newPaymentMethod.id == 3) {
                              let toTransfersTotal = 0;
                              transfers.map((trnsfr) => {
                                toTransfersTotal += trnsfr.amount;
                              });
                              newPaymentMethod.transfers = transfers;
                              newPaymentMethod.amount = toTransfersTotal;
                            }
                          });

                          setPaymentMethods(newPaymentMethods);
                          setTransferOpen(false);
                        }}
                      >
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={true}>
          <div
            className="flex sm:flex-row flex-col pb-1"
            style={{ borderBottom: "1px solid lightgray" }}
          >
            <div
              className="w-full sm:w-[25%]"
              style={{ borderRight: "1px solid lightgray" }}
            >
              <h1 className="ml-5 mt-5 text-3xl  title secondary-color font-family-thin">
                Pagos (Proveedores)
              </h1>
              <DateComponent className="sm:block hidden" />{" "}
            </div>
            <div className="w-[100%] sm:w-[70%] pt-2 ml-4">
              {width > 550 ? (
                <>
                  <div className="flex flex-row w-full">
                    <div className="w-[35%]">
                      <div className="mb-[1px]">
                        <ComboBox
                          data={providers}
                          setData={setProviderId}
                          value={providerId}
                          placeholder="PROVEEDOR"
                          className="h-[25px]"
                          search={searchProviders}
                        />
                      </div>

                      <div className="mb-[-1px]">
                        <input
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                          id="RUC"
                          type="text"
                          disabled
                          readOnly
                          tabIndex={-1}
                          value={provider?.ruc || ""}
                          placeholder="RUC"
                          onChange={(e) => { }}
                        />
                      </div>

                      <div className="mb-[-1px]">
                        <input
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize mt-[2px]"
                          id="address"
                          type="text"
                          readOnly
                          disabled
                          tabIndex={-1}
                          value={provider?.address || ""}
                          placeholder="DIRECCION"
                          onChange={(e) => { }}
                        />
                      </div>

                      <div>
                        <input
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize mt-[2px]"
                          id="phone"
                          type="text"
                          readOnly
                          disabled
                          tabIndex={-1}
                          value={provider?.phone || ""}
                          placeholder="TELEFONO"
                          onChange={(e) => { }}
                        />
                      </div>
                    </div>

                    <div className="w-[25%] ml-[1px]">
                      <div className="mb-[0px] flex flex-col">
                        <select
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px] mb-[1px]"
                          id="branch"
                          readOnly
                          disabled
                          tabIndex={-1}
                          value={branchId}
                          onChange={(e) => {
                            setBranchId(e.target.value);
                          }}
                        >
                          <option value={0}>Sucursal</option>
                          <option value={user.branch.id}>
                            Sucursal: {user.branch.description}
                          </option>
                        </select>

                        <select
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[22px] mb-[1px]"
                          id="boxId"
                          value={boxId}
                          disabled
                          readOnly
                          onChange={(e) => {
                            setBoxId(e.target.value);
                          }}
                        >
                          <option value={0}>Caja</option>
                          <option value={user.box.id}>{user.box.name}</option>
                        </select>
                      </div>

                      <div className="mb-[1px] flex flex-col">
                        <select
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight capitalize h-[23px] mb-[1px]"
                          id="branch"
                          value={currencyId}
                          onChange={(e) => {
                            setCurrencyId(e.target.value);
                          }}
                        >
                          <option value={0}>Moneda</option>
                          {currencies.map((currency, i) => (
                            <option value={currency.id} key={i}>
                              Moneda: {currency.name}
                            </option>
                          ))}
                        </select>
                        <select
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight capitalize h-[23px]"
                          id="sellerId"
                          value={sellerId}
                          onChange={(e) => {
                            setSellerId(e.target.value);
                          }}
                        >
                          <option value={0}>Cobrador</option>
                          {sellers.map((seller) => (
                            <option value={seller.id}>
                              Cobrador: {seller.fullname}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="w-[25%] ml-[1px]">
                      <div className="mb-[1px] flex flex-row">
                        <div className=" w-[49.3%] mr-[1px]">
                          <input
                            className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight capitalize h-[23px]"
                            id="expeditionPoint"
                            value={expeditionPoint}
                            type="expeditionPoint"
                            onChange={(e) => {
                              setExpeditionPoint(e.target.value);
                            }}
                          />
                        </div>
                        <div className="w-[50%]">
                          <input
                            className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight capitalize h-[23px]"
                            id="number"
                            value={number}
                            type="number"
                            onChange={(e) => {
                              setNumber(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="mt-[1px]">
                        <input
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight capitalize h-[23px]"
                          id="date"
                          value={date
                            .substring(0, 10)
                            .split("/")
                            .reverse()
                            .join("-")}
                          type="date"
                          onChange={(e) => {
                            setDate(e.target.value);
                          }}
                        />
                      </div>

                      <div className="mt-[1px]">
                        <textarea
                          disabled
                          readOnly
                          value={detail}
                          className="input-bg-color p-1 !rounded-[0] w-full h-[48.5%] opacity-75"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Tab.Group>
                    <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 mb-[2px]">
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                            "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                            providerId == 0 ? "bg-red-300" : "bg-green-200",
                            selected
                              ? "bg-white font-bold"
                              : "primary-color hover:text-slate-500"
                          )
                        }
                      >
                        Cliente
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                            "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                            selected
                              ? "bg-white font-bold"
                              : "primary-color hover:text-slate-500"
                          )
                        }
                      >
                        Datos
                      </Tab>
                      <Tab
                        onClick={() => { }}
                        className={({ selected }) =>
                          classNames(
                            "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                            "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                            selected
                              ? "bg-white font-bold"
                              : "primary-color hover:bg-white/[0.12] hover:text-slate-500"
                          )
                        }
                      >
                        Detalles
                      </Tab>
                    </Tab.List>
                    <Tab.Panels>
                      <Tab.Panel>
                        <Transition.Child
                          enter="ease-out duration-500"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-300"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="mb-[1px]">
                            <ComboBox
                              data={providers}
                              setData={setProviderId}
                              value={providerId}
                              placeholder="PROVEEDOR"
                              className="h-[25px] w-[99.7%]"
                              search={searchProviders}
                            />
                          </div>

                          <div className="mb-[-1px]">
                            <input
                              className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                              id="RUC"
                              type="text"
                              disabled
                              readOnly
                              tabIndex={-1}
                              value={provider?.ruc || ""}
                              placeholder="RUC"
                              onChange={(e) => { }}
                            />
                          </div>

                          <div className="mb-[-1px]">
                            <input
                              className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                              id="address"
                              type="text"
                              readOnly
                              disabled
                              tabIndex={-1}
                              value={provider?.address || ""}
                              placeholder="DIRECCION"
                              onChange={(e) => { }}
                            />
                          </div>

                          <div>
                            <input
                              className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                              id="phone"
                              type="text"
                              readOnly
                              disabled
                              tabIndex={-1}
                              value={provider?.phone || ""}
                              placeholder="TELEFONO"
                              onChange={(e) => { }}
                            />
                          </div>
                        </Transition.Child>
                      </Tab.Panel>
                      <Tab.Panel>
                        <Transition.Child
                          enter="ease-out duration-500"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-300"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="mb-[0px] flex flex-row">
                            <div className="w-full mt-[-1px] ml-[-1px]">
                              <select
                                className="input-bg-color appearance-none border w-[50%] py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[27px]"
                                id="branch"
                                readOnly
                                disabled
                                tabIndex={-1}
                                value={branchId}
                                onChange={(e) => {
                                  setBranchId(e.target.value);
                                }}
                              >
                                <option value={0}>Sucursal</option>
                                <option value={user.branch.id}>
                                  Sucursal: {user.branch.description}
                                </option>
                              </select>

                              <select
                                className="input-bg-color appearance-none border w-[50%] py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize mt-[-1px] h-[27px]"
                                id="boxId"
                                value={boxId}
                                disabled
                                readOnly
                                onChange={(e) => {
                                  setBoxId(e.target.value);
                                }}
                              >
                                <option value={0}>Caja</option>
                                <option value={user.box.id}>
                                  {user.box.name}
                                </option>
                              </select>
                            </div>
                          </div>

                          <div className="mb-[1px] flex flex-row">
                            <div className=" w-[49.3%] mr-[1px]">
                              <input
                                className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight capitalize h-[23px]"
                                id="expeditionPoint"
                                value={expeditionPoint}
                                type="expeditionPoint"
                                onChange={(e) => {
                                  setExpeditionPoint(e.target.value);
                                }}
                              />
                            </div>
                            <div className="w-[50%]">
                              <input
                                className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight capitalize h-[23px]"
                                id="number"
                                value={number}
                                type="number"
                                onChange={(e) => {
                                  setNumber(e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="mb-[1px] flex flex-row">
                            <div className="mt-[1px] w-[49.3%] mr-[1px]">
                              <select
                                className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight capitalize h-[23px]"
                                id="currency"
                                value={currencyId}
                                onChange={(e) => {
                                  setCurrencyId(e.target.value);
                                }}
                              >
                                <option value={0}>Moneda</option>
                                {currencies.map((currency, i) => (
                                  <option value={currency.id} key={i}>
                                    Moneda: {currency.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="mt-[1px] w-[50%]">
                              <select
                                className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight capitalize h-[23px] mt-[-1px]"
                                id="sellerId"
                                value={sellerId}
                                onChange={(e) => {
                                  setSellerId(e.target.value);
                                }}
                              >
                                <option value={0}>Cobrador</option>
                                {sellers.map((seller) => (
                                  <option value={seller.id}>
                                    Cobrador: {seller.fullname}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="mt-[1px]">
                            <input
                              className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight capitalize h-[23px]"
                              id="date"
                              value={date
                                .substring(0, 10)
                                .split("/")
                                .reverse()
                                .join("-")}
                              type="date"
                              onChange={(e) => {
                                setDate(e.target.value);
                              }}
                            />
                          </div>
                        </Transition.Child>
                      </Tab.Panel>
                      <Tab.Panel>
                        <Transition.Child
                          enter="ease-out duration-500"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-300"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <textarea
                            disabled
                            readOnly
                            value={detail}
                            className="input-bg-color p-1 !rounded w-full h-full opacity-75"
                          ></textarea>
                        </Transition.Child>
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </>
              )}
            </div>
          </div>

          <div className="w-[100%] p-2 mx-auto overflow-auto pb-4">
            <div className="bg-white px-2 pb-2 p-0 text-center rounded-3xl">
              <div className="flex sm:flex-row flex-col">
                <div className="w-full">
                  <div>
                    <div className="w-full flex flex-col">
                      <div className="w-full flex flex-row mt-[1px] p-1">
                        <div className="w-[100%] overflow-x-auto pb-2">
                          <div>
                            <div className="sales-table w-full">
                              <div className="text-gray">
                                <div>
                                  <h1 className="font-bold uppercase underline">
                                    Detalle de Facturas
                                  </h1>
                                </div>
                                <div className="tr flex border-b-2 bg-gray-600 mb-[1px]">
                                  <div className="w-[25%] sm:w-[14.2%] text-[11px] font-bold !text-white leading-5">
                                    NUMERO
                                  </div>
                                  <div className="w-[25%] sm:w-[14.2%] text-[11px] font-bold !text-white leading-5">
                                    EMISION
                                  </div>
                                  <div className="w-[25%] sm:w-[14.2%] text-[11px] font-bold !text-white leading-5">
                                    TOTALES
                                  </div>
                                  <div className="w-[14.2%] hidden sm:block text-[11px] font-bold !text-white whitespace-nowrap leading-5">
                                    NTA. CREDITO
                                  </div>
                                  <div className="w-[14.2%] hidden sm:block text-[11px] font-bold !text-white leading-5">
                                    RETENCIONES
                                  </div>
                                  <div className="w-[14.2%] hidden sm:block text-[11px] font-bold !text-white leading-5">
                                    PAGADO
                                  </div>
                                  <div className="w-[25%] sm:w-[14.2%] text-[11px] font-bold !text-white leading-5">
                                    SALDO
                                  </div>
                                  <div className="w-[14.2%] hidden sm:block text-[11px] font-bold !text-white leading-5">
                                    A COBRAR
                                  </div>
                                </div>
                              </div>

                              <div className="overflow-y-auto overflow-x-hidden">
                                {purchases &&
                                  purchases?.map((purchase, i) => {
                                    let totalAmount = 0;
                                    let totalReceived = 0;
                                    let totalToPay = 0;

                                    purchase?.purchasesdetails?.map(
                                      (detail) => {
                                        totalAmount +=
                                          detail.quantity * detail.price;
                                      }
                                    );

                                    purchase?.paymentdetails?.map((det) => {
                                      totalReceived += det.amount;
                                    });

                                    purchase.dues.map((due) => {
                                      due.paying = due.paying || 0;

                                      totalToPay +=
                                        Number(
                                          String(due.paying).replaceAll(",", "")
                                        ) || 0;

                                      // totalReceived +=
                                      //   Number(
                                      //     String(due.paying).replaceAll(",", "")
                                      //   ) || 0;
                                    });

                                    // to upload

                                    return (
                                      <div
                                        className={`tr flex text-xs ${purchase.selected ? "bg-gray-400" : ""
                                          }`}
                                        onClick={(e) => {
                                          let newPurchases = [...purchases];
                                          newPurchases.map((newPurchase) => {
                                            newPurchase.selected = false;
                                          });
                                          newPurchases.map((newPurchase) => {
                                            if (newPurchase.id == purchase.id) {
                                              newPurchase.selected = true;
                                            }
                                          });
                                          setPurchases(newPurchases);
                                          setPurchaseDetail(
                                            purchase.purchasesdetails
                                          );
                                          setPurchaseId(purchase.id);
                                          setPurchaseNumber(
                                            purchase.expeditionPoint +
                                            " " +
                                            purchase.number
                                          );
                                          purchase.dues.map(due => due.interests = '');
                                          setDues(purchase.dues);
                                        }}
                                      >
                                        <div className="td w-[25%] sm:w-[14.2%] m-[0.5px] whitespace-nowrap overflow-hidden">
                                          {purchase.expeditionPoint}{" "}
                                          {purchase.number}
                                        </div>

                                        <div className="td w-[25%] sm:w-[14.2%] m-[0.5px]">
                                          {purchase.createdAt
                                            .substr(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("/")}
                                        </div>
                                        <div className="td w-[25%] sm:w-[14.2%] m-[0.5px] text-right">
                                          {numberWithCommas(totalAmount)}
                                        </div>
                                        <div className="td hidden sm:block w-[14.2%] m-[0.5px] text-right">
                                          0
                                        </div>
                                        <div className="td hidden sm:block w-[14.2%] m-[0.5px] text-right">
                                          0
                                        </div>
                                        <div className="td hidden sm:block w-[14.2%] m-[0.5px] text-right text-green-800">
                                          {numberWithCommas(totalReceived)}
                                        </div>
                                        <div className="td w-[20%] sm:w-[14.2%] m-[0.5px] text-right text-red-600">
                                          {numberWithCommas(
                                            totalAmount - totalReceived
                                          )}
                                        </div>
                                        <div className="td hidden sm:block w-[25%] sm:w-[14.2%] m-[0.5px] text-right text-green-800 font-bold">
                                          {numberWithCommas(totalToPay)}
                                        </div>
                                      </div>
                                    );
                                  })}

                                <div
                                  className="tr flex text-xs"
                                  style={{ borderTop: "1px solid gray" }}
                                >
                                  <div className="td w-[25%] sm:w-[14.2%] m-[0.5px]"></div>
                                  <div className="td w-[25%] sm:w-[14.2%] m-[0.5px]">
                                    <b>TOTALES:</b>
                                  </div>
                                  <div className="td w-[25%] sm:w-[14.2%] m-[0.5px] text-right">
                                    <b>{numberWithCommas(endTotal)}</b>
                                  </div>
                                  <div className="td hidden sm:block w-[14.2%] m-[0.5px] text-right">
                                    <b>0</b>
                                  </div>
                                  <div className="td hidden sm:block w-[14.2%] m-[0.5px] text-right">
                                    <b>0</b>
                                  </div>
                                  <div className="td hidden sm:block w-[14.2%] m-[0.5px] text-right text-green-800">
                                    <b>{numberWithCommas(endTotalReceived)}</b>
                                  </div>
                                  <div className="td w-[20%] sm:w-[14.2%] m-[0.5px] text-right text-red-600">
                                    <b>{numberWithCommas(endTotalRest)}</b>
                                  </div>
                                  <div className="td hidden sm:block w-[25%] sm:w-[14.2%] m-[0.5px] text-right text-green-800 font-bold">
                                    <b>{numberWithCommas(amountToCharge)}</b>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="sales-table w-full mt-5 ">
                              <div className="text-gray">
                                <div>
                                  <h1 className="font-bold uppercase underline">
                                    Detalle de Financiacion
                                    {purchaseNumber != 0 &&
                                      " Factura " + purchaseNumber}
                                  </h1>
                                </div>
                                <div className="tr flex border-b-2 bg-gray-600 mb-[1px]">
                                  <div className="w-[33%] sm:w-[20%] text-[11px] font-bold !text-white leading-5">
                                    NUMERO
                                  </div>
                                  <div className="w-[33%] sm:w-[20%] text-[11px] font-bold !text-white leading-5">
                                    FEC. VENC.
                                  </div>
                                  <div className="w-[33%] sm:w-[20%] text-[11px] font-bold !text-white leading-5">
                                    MONTO
                                  </div>
                                  <div className="w-[20%] hidden sm:block text-[11px] font-bold !text-white leading-5">
                                    A COBRAR
                                  </div>
                                  <div className="w-[20%] hidden sm:block text-[11px] font-bold !text-white leading-5"></div>
                                </div> 
                              </div>
                              <div className="overflow-auto sm:max-h-[35vh] max-h-[40vh]">
                                {dues.map((due, i) => {
                                  if (
                                    Number(
                                      String(due.paid).replaceAll(",", "")
                                    ) ==
                                    Number(
                                      String(due.amount).replaceAll(",", "")
                                    )
                                  )
                                    return <></>;

                                  if (width > 640) {
                                    return (
                                      <div className="tr flex text-xs">
                                        <div className="td w-[20%] m-[0.5px]">
                                          {due.number}/{dues?.length}
                                        </div>
                                        <div className="td w-[20%] m-[0.5px]">
                                          {due.expirationDate
                                            .substr(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("/")}
                                        </div>
                                        <div className="td w-[20%] m-[0.5px]">
                                          {numberWithCommas(
                                            due.amount -
                                            Number(
                                              String(due.paid).replaceAll(
                                                ".",
                                                ""
                                              )
                                            )
                                          )}
                                        </div>
                                        <div className="td w-[20%] m-[0.5px] flex flex-row justify-center items-center">
                                          <input
                                            className={`w-32 input-bg-color p-1 h-[20px] font-lg relative left-[-5px]`}
                                            name="dueAmount"
                                            value={numberWithCommas(due.paying)}
                                            // onKeyPress={(e) => {
                                            //   e.preventDefault();
                                            //   e.stopPropagation();
                                            // }}
                                            onChange={(e) => {
                                              if (
                                                Number(
                                                  String(
                                                    e.target.value
                                                  ).replaceAll(",", "")
                                                ) > due.amount
                                              ) {
                                                return false;
                                              } 

                                              let newDues = [...dues];
                                               newDues.map((newDue) => {
                                                if (
                                                  newDue.number == due.number
                                                ) {
                                                  newDue.paying = 
                                                    String(
                                                      e.target.value
                                                    ).replaceAll(",", "")
                                                }
                                              });
                                              setDues(newDues);
                                            }}
                                          />

                                         {/*  <input
                                            className={`w-32 input-bg-color p-1 h-[20px] font-lg relative left-[-5px]`}
                                            name="dueInterests"
                                            placeholder="Intereses"
                                            value={numberWithCommas(due.interests)}
                                            // onKeyPress={(e) => {
                                            //   e.preventDefault();
                                            //   e.stopPropagation();
                                            // }}
                                            onChange={(e) => {
                                              
                                              let newDues = [...dues];
                                              newDues.map((newDue) => {
                                                if (
                                                  newDue.number == due.number
                                                ) {
                                                  newDue.interests = 
                                                    String(
                                                      e.target.value
                                                    ).replaceAll(",", "");
                                                }
                                              });
                                              setDues(newDues);
                                            }}
                                          /> */}

                                          {/* <input
                                            type="checkbox"
                                            className={`w-24  input-bg-color p-1 w-[25px] h-[25px] font-lg`}
                                            value={due.toPay}
                                            onChange={(e) => {
                                              let newDues = [...dues];
                                              newDues.map((newDue) => {
                                                if (newDue.id == due.id) {
                                                  newDue.toPay =
                                                    e.target.checked;
                                                }
                                              });
                                            }}
                                          /> */}
                                        </div>

                                        <div className="td w-[20%] m-[0.5px]">
                                          {/* <button
                                          className={`${
                                            due.buttonVisible
                                              ? "block"
                                              : "hidden"
                                          } secondary-color-bg font-black p-1 text-xs ml-5`}
                                          onClick={async (e) => {
                                            if (due.paying <= 0) return false;

                                            let newSales = [...sales];

                                            newSales.map((newSale) => {
                                              if (newSale.id == saleId) {
                                                newSale.payments.push({
                                                  saleId,
                                                  purchaseId: null,
                                                  dueId: due.id,
                                                  paymentMethodId: 1,
                                                  amount: Number(
                                                    String(
                                                      due.paying
                                                    ).replaceAll(",", "")
                                                  ),
                                                  createdAt:
                                                    new Date().toLocaleDateString(),
                                                  updatedAt:
                                                    new Date().toLocaleDateString(),
                                                });

                                                let newDues = [...newSale.dues];

                                                newDues.map((newDue) => {
                                                  if (newDue.id == due.id) {
                                                    newDue.buttonVisible = false;
                                                    newDue.paid =
                                                      Number(
                                                        String(
                                                          newDue.paid
                                                        ).replaceAll(",", "")
                                                      ) +
                                                      Number(
                                                        String(
                                                          due.paying
                                                        ).replaceAll(",", "")
                                                      );
                                                  }
                                                });
                                              }
                                            });

                                            setSales(newSales);
                                          }}
                                        >
                                          COBRAR
                                        </button> */}
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div className="tr flex flex-col text-xs pb-1 pt-1 border-b-[1px] border-slate-400">
                                        <div className="flex flex-row">
                                          <div className="td w-[25%] m-[0.5px]">
                                            {due.number}/{dues?.length}
                                          </div>
                                          <div className="td w-[25%] m-[0.5px]">
                                            {/* <input
                                              type="checkbox"
                                              className={`w-24 mr-2 input-bg-color p-1 w-[25px] h-[25px] font-lg`}
                                              value={due.toPay}
                                              onChange={(e) => {}}
                                            /> */}
                                            <input
                                              className={`w-32 input-bg-color p-1 h-[20px] font-lg relative left-[-5px]`}
                                              name="dueAmount"
                                              value={numberWithCommas(
                                                due.paying
                                              )}
                                              // onKeyPress={(e) => {
                                              //   e.preventDefault();
                                              //   e.stopPropagation();
                                              // }}
                                              onChange={(e) => {
                                                if (
                                                  Number(
                                                    String(
                                                      e.target.value
                                                    ).replaceAll(",", "")
                                                  ) > due.amount
                                                ) {
                                                  return false;
                                                }

                                                let newDues = [...dues];
                                                newDues.map((newDue) => {
                                                  if (
                                                    newDue.number == due.number
                                                  ) {
                                                    newDue.paying = Number(
                                                      String(
                                                        e.target.value
                                                      ).replaceAll(",", "")
                                                    );
                                                  }
                                                });
                                                setDues(newDues);
                                              }}
                                            />
                                            <input
                                            className={`w-32 input-bg-color p-1 h-[20px] font-lg relative left-[-5px]`}
                                            name="dueInterests"
                                            placeholder="Intereses"
                                            value={numberWithCommas(due.interests)}
                                            // onKeyPress={(e) => {
                                            //   e.preventDefault();
                                            //   e.stopPropagation();
                                            // }}
                                            onChange={(e) => {
                                              
                                              let newDues = [...dues];
                                              newDues.map((newDue) => {
                                                if (
                                                  newDue.number == due.number
                                                ) {
                                                  newDue.interests = 
                                                    String(
                                                      e.target.value
                                                    ).replaceAll(",", "");
                                                }
                                              });
                                              setDues(newDues);
                                            }}
                                          />
                                          </div>
                                          <div className="td w-[25%] m-[0.5px]">
                                            {due.expirationDate
                                              .substr(0, 10)
                                              .split("-")
                                              .reverse()
                                              .join("/")}
                                          </div>
                                          <div className="td w-[25%] m-[0.5px]">
                                            {numberWithCommas(
                                              due.amount -
                                              Number(
                                                String(due.paid).replaceAll(
                                                  ".",
                                                  ""
                                                )
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1
                    className="text-xl font-bold text-right"
                    style={{ borderTop: "1px solid gray" }}
                  >
                    <span className="uppercase text-xs">Total a cobrar:</span>{" "}
                    <b className="text-green-800">
                      {numberWithCommas(amountToCharge)}
                    </b>
                  </h1>
                </div>

                <div className="w-full sm:w-[25%] my-[4px] mx-2">
                  <h1 className="font-bold uppercase underline">
                    Formas de pago
                  </h1>
                  {paymentMethods.map((payment, p) => {
                    if (payment.id == 2) {
                      let toChkAmount = 0;
                      payment.checks.map((chk) => {
                        toChkAmount += chk.amount;
                      });
                      payment.amount = toChkAmount;
                    }

                    if (payment.id == 3) {
                      let toTransferAmount = 0;
                      payment.transfers.map((trnsfr) => {
                        toTransferAmount += trnsfr.amount;
                      });
                      payment.amount = toTransferAmount;
                    }

                    return (
                      <>
                        <input
                          onKeyPress={(e) => {
                            if (payment.id == 2 || payment.id == 3) {
                              e.preventDefault();
                              e.stopPropagation();
                            }
                          }}
                          onClick={() => {
                            if (payment.id == 2) {
                              setChecksOpen(true);
                            }
                            if (payment.id == 3) {
                              setTransferOpen(true);
                            }
                          }}
                          onChange={(e) => {
                            let newPaymentMethods = [...paymentMethods];
                            newPaymentMethods.map((payment, np) => {
                              if (np == p) {
                                payment.amount = Number(
                                  String(e.target.value).replaceAll(",", "")
                                );
                              }
                            });
                            setPaymentMethods(newPaymentMethods);
                          }}
                          placeholder={payment.name}
                          value={
                            payment.amount > 0
                              ? numberWithCommas(payment.amount)
                              : ""
                          }
                          className="input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center mb-[1px] font-bold !text-[14px]"
                        />
                      </>
                    );
                  })}

                  <h1
                    className="text-xl font-bold text-right mt-[1px]"
                    style={{ borderTop: "1px solid gray" }}
                  >
                    <span className="uppercase text-xs">
                      valores recibidos:
                    </span>{" "}
                    <b>{numberWithCommas(amountToPay)}</b>
                  </h1>

                  {amountToCharge - amountToPay != 0 && (
                    <h1
                      className="text-xl font-bold text-right mt-1"
                      style={{ borderTop: "1px solid gray" }}
                    >
                      <span
                        className={`uppercase text-xs ${amountToCharge - amountToPay <= 0
                            ? "text-green-800"
                            : "text-red-800"
                          }`}
                      >
                        {amountToCharge - amountToPay <= 0 ? "Sobra" : "Falta"}:
                      </span>{" "}
                      <b
                        className={`${amountToCharge - amountToPay <= 0
                            ? "text-green-800"
                            : "text-red-800"
                          }`}
                      >
                        {numberWithCommas(amountToCharge - amountToPay)}
                      </b>
                    </h1>
                  )}

                  {/* {buttonVisible && <button
                    type="button"
                    className="w-full rounded font-family-thin secondary-bg-color justify-center px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 font-family-thin"
                    onClick={() => {
                      let newSales = [...sales];
                      newSales.map((newSale) => {
                        if (newSale.id == saleId) {
                          let totalToPay = 0;
                          // let isDueChecked = false;

                          let newDues = [...newSale.dues];

                          // newDues.map((newDue) => {
                          //   if (newDue.toPay) {
                          //     isDueChecked = true;
                          //   }
                          // });

                          // if (!isDueChecked) return false;

                          paymentMethods.map((payment) => {
                            if (payment.amount > 0) {
                              newSale.payments.push({
                                saleId,
                                purchaseId: null,
                                dueId: null,
                                paymentMethodId: payment.id,
                                paymentmethod: payment,
                                amount: payment.amount,
                                createdAt: new Date().toLocaleDateString(),
                                updatedAt: new Date().toLocaleDateString(),
                              });

                              totalToPay += payment.amount;
                            }
                          });

                          newDues.map((newDue) => {

                            newDue.paid = Number(String(newDue.paid).replaceAll(",", "")) + Number(String(newDue.paying).replaceAll(",", ""));

                            // if (newDue.toPay) {
                            //   if (newDue.paid == NaN) {
                            //     newDue.paid = 0;
                            //   }

                            //   if (totalToPay > newDue.amount) {
                            //     let toRest = newDue.amount - newDue.paid;

                            //     totalToPay -= toRest;

                            //     newDue.paid =
                            //       Number(
                            //         String(newDue.paid).replaceAll(",", "")
                            //       ) +
                            //       Number(String(toRest).replaceAll(",", ""));
                            //   } else {
                            //     if (totalToPay + newDue.paid > newDue.amount) {
                            //       let toRest =
                            //         totalToPay + newDue.paid - newDue.amount;

                            //       totalToPay -= toRest;

                            //       newDue.paid += totalToPay;
                            //     } else {
                            //       newDue.paid =
                            //         Number(
                            //           String(newDue.paid).replaceAll(",", "")
                            //         ) +
                            //         Number(
                            //           String(totalToPay).replaceAll(",", "")
                            //         );
                            //     }
                            //   }
                            // }
                          });
                          setDues(newDues);

                          setButtonVisible(!buttonVisible);

                        }
                      });
                      setSales(newSales);
                      setAmountToCharge(0);
                      setPaymentMethods(cleanPaymentMethods);
                    }}
                  >
                    COBRAR
                  </button>
                  } */}
                </div>
              </div>

              <div className="px-2 py-2 justify-end flex">
                <button
                  type="button"
                  className="w-[65px] h-[73px] inline-flex flex-col justify-center items-center
                   rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset 
                   ring-gray-300 hover:bg-gray-400 sm:mt-0"
                  onClick={() => {
                    clearFields();
                    let newPaymentMethods = [...paymentMethods];
                    newPaymentMethods.map((paymentMethod) => {
                      paymentMethod.amount = 0;
                    });
                    setPaymentMethods(newPaymentMethods);
                  }}
                >
                  <img src={broom} className="w-8" />
                  LIMPIAR
                </button>
                <button
                  type="button"
                  className="inline-flex w-[65px] h-[73px] ml-1 mr-1 flex-col 
                  justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs 
                  text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                  style={{ alignItems: "center" }}
                  onClick={() => {
                    if (
                      amountToCharge != amountToPay ||
                      amountToCharge <= 0 ||
                      amountToPay <= 0
                    ) {
                      alert(
                        "Para poder cobrar la factura los montos deben coincidir"
                      );
                      return false;
                    }

                    handleSubmit();
                  }}
                >
                  <img src={floppy} className="w-8" />
                  GUARDAR
                </button>

                <button
                  type="button"
                  className="inline-flex w-[85px] h-[73px] ml-1 mr-1 flex-col 
                  justify-center items-center rounded ternary-bg-color px-3 py-2 text-[9px] 
                  text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                  style={{ alignItems: "center" }}
                  onClick={() => {
                    if (
                      providerId == 0 ||
                      purchases.length <= 0 ||
                      dues.length <= 0
                    ) {
                      let notPaying = true;
                      dues.map((due) => {
                        if (due.paying > 0) {
                          notPaying = false;
                        }
                      });

                      if (notPaying) {
                        alert(
                          "Para guardar la orden de pago debe cargar montos a cobrar"
                        );
                      }
                      return false;
                    }

                    handlePaymentOrderSubmit();
                  }}
                >
                  <img src={cash} className="w-8" />
                  CREAR ORDEN DE PAGO
                </button>

                <button
                  type="button"
                  className="inline-flex w-[65px] h-[73px] ml-[-0px] flex-col 
                  justify-center items-center rounded ternary-bg-color px-3 py-2 
                  text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                  style={{ alignItems: "center" }}
                  onClick={() => navigate("/")}
                >
                  <img src={door} className="w-5" />
                  SALIR
                </button>
              </div>
            </div>
          </div>
        </Transition.Root>
      </Layout>
    </>
  );
};

export default ProviderPayments;
