import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import floppy from "../assets/img/floppy.png";
import door from "../assets/img/door.png";

const Adjustments = () => {
    const dispatch = useDispatch();
    const [deposits, setDeposits] = useState([]);
    const [description, setDescription] = useState("");
    const [selectedDeposit, setSelectedDeposit] = useState("");
    const [date, setDate] = useState(new Date());
    const [pages, setPages] = useState(0);
    const [itemSearch, setItemSearch] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [adjustmentItems, setAdjustmentItems] = useState([]);
    const containerRef = useRef(null);
    const inputRefs = useRef([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [debouncedItemSearch, setDebouncedItemSearch] = useState(itemSearch);
    useEffect(() => {
        fetchDeposits();
    }, []);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedItemSearch(itemSearch);
        }, 500); // 500ms delay

        return () => {
            clearTimeout(handler);
        };
    }, [itemSearch]);
    async function fetchDeposits() {
    
        let pageQuery = 1;
        try {
            const response = await axiosAuth.get(`/deposits?page=${pageQuery}`);
            if (!response.data.error) {
                console.log(response);
                setDeposits(response.data.deposits.rows);
            } else {
                if (response.data.message === "token") {
                    await axiosAuth.post("/logout");
                    dispatch(setUser(null));
                    dispatch(setToken(null));
                }
            }
        } catch (error) {
            console.error("Error fetching deposits:", error);
        }
    }

    const handleTokenError = async (response) => {
        if (response.data.message === "token") {
            await axiosAuth.post("/logout");
            dispatch(setUser(null));
            dispatch(setToken(null));
        }
    };

    const searchItems = async (query) => {
        try {
            const response = await axiosAuth.get(`/searchItems?search=${query}&page=1&stock=true`);
            if (!response.data.error) {
                setSearchResults(response.data.items.rows);
            } else {
                handleTokenError(response);
            }
            setHighlightedIndex(-1);
        } catch (error) {
            console.error("Error searching items:", error);
        }
    };
    

    const addItemToAdjustment = (item) => {
        const exists = adjustmentItems.find((adjustItem) => adjustItem.id === item.id);
        if (exists) {
            alert("Este ítem ya ha sido agregado.");
            return;
        }

        const stockExistence = item.stocks && item.stocks.length > 0 ? item.stocks[0].existence : 0;

        setAdjustmentItems((prevItems) => [
            ...prevItems,
            {
                id: item.id,
                name: item.name,
                current_stock: stockExistence,
                adjustment_quantity: 0,
                target_quantity: 0,
            },
        ]);

        setSearchResults([]);
        setItemSearch("");
        setTimeout(() => {
            const newIndex = adjustmentItems.length;
            if (inputRefs.current[newIndex]) {
                inputRefs.current[newIndex].focus();
            }
        }, 0);
        setHighlightedIndex(-1);
    };

    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            setHighlightedIndex((prevIndex) =>
                Math.min(prevIndex + 1, searchResults.length - 1)
            );
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
        } else if (e.key === "Enter" && highlightedIndex >= 0) {
            e.preventDefault();
            addItemToAdjustment(searchResults[highlightedIndex]);
        }
    };

    const updateAdjustmentItem = (id, field, value) => {
        setAdjustmentItems(
            adjustmentItems.map((item) => {
                if (item.id === id) {
                    let updatedItem = { ...item, [field]: value };

                    if (field === "target_quantity") {
                        value = value.replace(",", ".");

                        if (value.endsWith(".")) {
                            updatedItem[field] = value;
                        } else {
                            const parsedValue = parseFloat(value);
                            if (!isNaN(parsedValue)) {
                                updatedItem[field] = parsedValue;
                                updatedItem.adjustment_quantity = parsedValue - item.current_stock;
                            } else {
                                updatedItem[field] = 0;
                                updatedItem.adjustment_quantity = 0 - item.current_stock;
                            }
                        }
                    }

                    return updatedItem;
                }
                return item;
            })
        );
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };

    const removeAdjustmentItem = (id) => {
        setAdjustmentItems(adjustmentItems.filter((item) => item.id !== id));
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };

    const handleCreateAdjustment = async () => {
        if (description.trim() === "" || selectedDeposit === "") {
            alert("Por favor, completa la descripción y selecciona un depósito.");
            return;
        }

        if (adjustmentItems.length === 0) {
            alert("Agrega al menos un ítem al ajuste.");
            return;
        }

        const payload = {
            name: description,
            deposit_id: selectedDeposit,
            adjustment_date: date.toISOString().split('T')[0],
            adjustmentdetails: adjustmentItems.map((item) => ({
                item_id: item.id,
                current_stock: item.current_stock,
                adjustment_quantity: item.adjustment_quantity,
                target_quantity: item.target_quantity,
            })),
        };

        try {
            const response = await axiosAuth.post("/adjustment-groups", payload);
            if (!response.data.error) {
                alert("Ajuste creado exitosamente.");
                clearCreateFields();
            } else {
                handleTokenError(response);
                alert(response.data.message || "Error al crear el ajuste.");
            }
        } catch (error) {
            console.error("Error creating adjustment:", error);
            alert("Error al crear el ajuste. Por favor, intenta de nuevo.");
        }
    };

    const clearCreateFields = () => {
        setDescription("");
        setSelectedDeposit("");
        setAdjustmentItems([]);
        setDate(new Date());
    };
    useEffect(() => {
        if (debouncedItemSearch.trim() !== "") {
            searchItems(debouncedItemSearch);
        } else {
            setSearchResults([]);
        }
    }, [debouncedItemSearch]);

    return (
        <Layout>
            <div className="p-4 sm:p-6 bg-white rounded-lg shadow-md">
                <h1 className="text-2xl font-semibold mb-4">Ajuste de Stock</h1>

                <div className="space-y-4">
                    <div className="flex flex-col sm:flex-row items-center sm:items-start space-y-2 sm:space-y-0 sm:space-x-3">
                        <label className="text-gray-700 text-lg font-bold w-full sm:w-1/6 text-left !text-xl" style={{ width: "10%" }} htmlFor="description">
                            Nombre
                        </label>
                        <span className="text-gray-700 text-lg font-bold text-left !text-xl">:</span>
                        <input
                            style={{ width: "100%" }}
                            type="text"
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="shadow appearance-none border rounded w-full sm:w-3/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                            placeholder="Descripción del ajuste"
                        />
                    </div>
                    <div className="flex flex-col sm:flex-row items-center sm:items-start space-y-2 sm:space-y-0 sm:space-x-3 border-t border-gray-300" style={{
                        margin: "4px 0",
                    }}>
                        <label style={{ width: "10%" }} className="text-gray-700 text-xl font-bold w-full sm:w-1/6 text-left !text-xl" htmlFor="deposit">
                            Depósito
                        </label>
                        <span className="text-gray-700 text-lg font-bold text-left !text-xl">:</span>
                        <select
                            style={{ width: "100%" }}
                            id="deposit"
                            value={selectedDeposit}
                            onChange={(e) => setSelectedDeposit(e.target.value)}
                            className="shadow appearance-none border rounded w-full sm:w-3/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                        >
                            <option value="">Selecciona un depósito</option>
                            {deposits.map((deposit) => (
                                <option key={deposit.id} value={deposit.id}>
                                    {deposit.description}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="border border-gray-600" style={{
                        width: "100%",
                        height: "1.5px",
                        background: "#4b556370",
                        margin: "8px 0px"
                    }}></div>
                    <div className="flex flex-col sm:flex-row items-center sm:items-start space-y-2 sm:space-y-0 sm:space-x-3 relative " style={{ margin: "0" }} >
                        <label className="text-gray-700 text-xl font-bold w-full sm:w-1/6 text-left !text-xl" style={{ width: "10%" }} htmlFor="itemSearch">
                            Buscar
                        </label>
                        <span className="text-gray-700 text-lg font-bold text-left !text-xl">:</span>
                        <input
                            style={{ width: "100%" }}
                            type="text"
                            id="itemSearch"
                            value={itemSearch}
                            onChange={(e) => setItemSearch(e.target.value)}
                            onKeyDown={handleKeyDown}
                            className="shadow appearance-none border rounded w-full sm:w-3/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                            placeholder="Escribe para buscar ítems"
                        />
                        {searchResults.length > 0 && (
                            <ul className="absolute z-10 border border-gray-300 rounded mt-1 max-h-48 overflow-y-auto bg-white shadow-lg top-16 left-1/2 transform -translate-x-1/2 w-full sm:w-3/4">
                                {searchResults.map((item, index) => (
                                    <li
                                        key={item.id}
                                        onClick={() => addItemToAdjustment(item)}
                                        className={`px-4 py-2 hover:bg-gray-200 cursor-pointer transition duration-200 ${index === highlightedIndex ? 'bg-gray-100' : ''
                                            }`}
                                    >
                                        {item.name} - Stock: {item.stocks.length > 0 ? item.stocks[0].existence : 0}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>

                <div className="mt-4">
                    <div className="overflow-x-auto">
                        <div className="overflow-y-auto" ref={containerRef} style={{
                            maxHeight: "300px",
                            borderLeft: "1px solid #4b5563",
                            borderRight: "1px solid #4b5563",
                            borderBottom: "1px solid #4b5563",
                        }}>
                            <table className="min-w-full table-auto table-layout-fixed text-lg">
                                <thead className="bg-gray-600 text-white">
                                    <tr>
                                        <th className="px-4 py-2 !text-white text-left w-[50%]">Artículos</th>
                                        <th className="px-4 py-2 !text-white text-center w-[12%]">Stock</th>
                                        <th className="px-4 py-2 !text-white text-center w-[12%]">Ajuste</th>
                                        <th className="px-4 py-2 !text-white text-center w-[18%]">Stock final</th>
                                        <th className="px-4 py-2 !text-white text-left w-[8%]">Borrar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {adjustmentItems.map((item, index) => (
                                        <tr key={item.id} className="bg-white hover:bg-gray-50 transition duration-200">
                                            <td className="border-t border-gray-300 px-4 w-[50%] text-left">
                                                {item.name}
                                            </td>
                                            <td className="border-t border-gray-300 px-4 w-[12%] text-right text-xl">
                                                {item.current_stock}
                                            </td>
                                            <td className="border-t border-gray-300 px-4 w-[12%] text-right !text-xl">
                                                <span
                                                    className={item.adjustment_quantity > 0
                                                        ? "text-green-500 !text-xl"
                                                        : item.adjustment_quantity < 0
                                                            ? "text-red-500 !text-xl"
                                                            : "text-black !text-xl"
                                                    }
                                                >
                                                    {item.adjustment_quantity}
                                                </span>
                                            </td>
                                            <td className="border-t border-gray-300 px-4 w-[18%] text-right">
                                                <input
                                                    type="text"
                                                    ref={(el) => (inputRefs.current[index] = el)}
                                                    value={item.target_quantity}
                                                    onChange={(e) =>
                                                        updateAdjustmentItem(
                                                            item.id,
                                                            "target_quantity",
                                                            e.target.value
                                                        )
                                                    }
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            e.preventDefault();
                                                            document.getElementById("itemSearch").focus();
                                                        }
                                                    }}
                                                    className="shadow appearance-none px-2 border-2 border-blue-400  w-[60%] text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-400 text-right text-lg"
                                                />
                                            </td>
                                            <td className="border-t border-gray-300 px-4 w-[8%]">
                                                <button
                                                    onClick={() => removeAdjustmentItem(item.id)}
                                                    className="text-red-500"
                                                    title="Eliminar Ítem"
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    {/* Añadir filas vacías si hay menos de 8 elementos */}
                                    {adjustmentItems.length < 13 &&
                                        Array.from({ length: 13 - adjustmentItems.length }).map(
                                            (_, index) => (
                                                <tr key={`empty-${index}`} className="bg-white">
                                                    <td className="border-t border-gray-300 px-4 w-[50%] text-left">
                                                        &nbsp;
                                                    </td>
                                                    <td className="border-t border-gray-300 px-4 w-[12%] text-center">
                                                        &nbsp;
                                                    </td>
                                                    <td className="border-t border-gray-300 px-4 w-[12%] text-center">
                                                        &nbsp;
                                                    </td>
                                                    <td className="border-t border-gray-300 px-4 w-[18%]">
                                                        &nbsp;
                                                    </td>
                                                    <td className="border-t border-gray-300 px-4 w-[8%]">
                                                        &nbsp;
                                                    </td>
                                                </tr>
                                            )
                                        )}

                                    <tr>
                                        <td
                                            colSpan="5"
                                            className="border-t border-gray-300 px-4  text-center"
                                        >
                                            No hay ítems agregados.
                                        </td>
                                    </tr>

                                    {/* Añadir filas vacías si no hay ítems */}
                                    {Array.from({ length: 7 }).map((_, index) => (
                                        <tr key={`empty-${index}`} className="bg-white">
                                            <td className="border-t border-gray-300 px-4 w-[50%] text-left">
                                                &nbsp;
                                            </td>
                                            <td className="border-t border-gray-300 px-4 w-[12%] text-center">
                                                &nbsp;
                                            </td>
                                            <td className="border-t border-gray-300 px-4 w-[12%] text-center">
                                                &nbsp;
                                            </td>
                                            <td className="border-t border-gray-300 px-4 w-[18%]">
                                                &nbsp;
                                            </td>
                                            <td className="border-t border-gray-300 px-4 w-[8%]">
                                                &nbsp;
                                            </td>
                                        </tr>
                                    ))}


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center space-x-2">


                    <button
                        type="button"
                        className="relative inline-flex w-[68px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400"
                        onClick={handleCreateAdjustment}
                    >
                        <img src={floppy} className="w-8" />
                        GUARDAR

                    </button>
                </div>
            </div>
        </Layout>

    );
};

export default Adjustments;