import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import search from "../assets/img/search.webp";
import door from "../assets/img/door.png";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import { numberWithCommas } from "../utils/constants";
import ComboBox from "../components/combobox";
import useViewport from "../utils/useViewport";


const Movements = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [itemId, setItemId] = useState(0);
  const [depositId, setDepositId] = useState(0);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [movements, setMovements] = useState([]);
  const [items, setItems] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const { width } = useViewport();
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  async function fetchMovements() {
    setMovements([]);
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let movementsRequest = await axiosAuth.get(
      "/movements?page=" +
      pageQuery +
      "&companyId=" +
      user.companyId +
      "&branchId=" +
      user.branch.id +
      "&itemId=" +
      itemId +
      "&depositId=" +
      depositId +
      "&dateFrom=" +
      dateFrom +
      "&dateTo=" +
      dateTo
    );

    if (movementsRequest.data.error) {
      if (movementsRequest.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setMovements(movementsRequest.data.movements.rows);
    setTotal(movementsRequest.data.movements.count);
    setPages(movementsRequest.data.movements.pages);
    setItems(movementsRequest.data.items.rows);
    setDeposits(movementsRequest.data.deposits);
  }

  useEffect(() => {
    fetchMovements();
  }, []);

  useEffect(() => {
    if (location.state != null) {
      if (location.state.itemId > 0) {
        setItemId(location.state.itemId);
      }
      if (location.state.depositId > 0) {
        setDepositId(location.state.depositId);
      }
      if (location.state.dateFrom != "") {
        setDateFrom(location.state.dateFrom);
      }
      if (location.state.dateTo != "") {
        setDateTo(location.state.dateTo);
      }
    }

  }, [location]);

  /* useEffect(() => {
    fetchMovements();
  }, [itemId, depositId, dateFrom, dateTo]); */

  const searchItems = async (search) => {
    const searchItemsRequest = await axiosAuth.get(
      "/searchItems?search=" + search
    );

    setItems(searchItemsRequest.data.items.rows);
  };

  return (
    <>
      <Layout>
        <div className="flex flex-col lg:flex-row border border-gray-400 border-r-0 border-b-3">
          <div className="w-[100%] sm:w-[50%] lg:w-[25%] border border-gray-400  border-b-5 h-[98%]">
            <h1 className="ml-5 mt-5 text-3xl secondary-color title font-family-thin">
              Movimientos
            </h1>
            <DateComponent className="!text-[15px] sm:block hidden" />
          </div>

          <div className="flex relative right-[-30px] sm:right-[0px] flex-row justify-start items-start ml-0 sm:ml-[8%] w-[100%] sm:w-[100%] lg:w-[75%] mt-1">
            <div className="flex flex-row justify-start items-center w-[60%] lg:w-[40%]">
              <div className="mr-5 w-[100%]">
                <ComboBox
                  data={items}
                  setData={setItemId}
                  value={itemId}
                  placeholder="Articulos"
                  className="h-[28px] left-[0px] input-bg-color w-full"
                  search={searchItems}
                />
                <ComboBox
                  data={deposits}
                  setData={setDepositId}
                  value={depositId}
                  placeholder="Deposito"
                  className="h-[28px] left-[0px] input-bg-color w-full"
                />
                <div className="flex flex-row">
                  <input
                    className="input-bg-color appearance-none border w-1/2 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[22px] font-[11px] mt-[1px]"
                    type="date"
                    value={dateFrom}
                    onChange={(e) => setDateFrom(e.target.value)}
                  />
                  <input
                    className="input-bg-color appearance-none border w-1/2 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[22px] font-[11px] mt-[1px]"
                    type="date"
                    value={dateTo}
                    onChange={(e) => setDateTo(e.target.value)}
                  />
                </div>
              </div>

            </div>

            <div
              className="flex flex-row sm:justify-center mt-1 sm:mt-0 pl-0 sm:pl-8 w-[60%] sm:w-[50%] lg:w-[69%] mt-2.5 h-[75px] mr-0 lg:mr-[8%] overflow-x-auto overflow-y-hidden overflow-visible"
              style={{ margin: "0px auto" }}
            >
              <div className="flex flex-col items-center h-[70px]">
                <button
                  type="button"
                  className="inline-flex flex-col w-[85px] h-[70px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                  onClick={() => {
                    fetchMovements();
                  }}
                >
                  <img src={search} className="w-8" />
                  CONSULTAR
                </button>
              </div>
              <div className="flex flex-col items-center h-[70px]">
                <button
                  type="button"
                  className="inline-flex flex-col w-[85px] h-[70px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img src={door} className="w-6" />
                  SALIR
                </button>
              </div>


            </div>
          </div>
        </div>




        <div className="w-[95%] p-5 mx-auto overflow-auto">
          {
            width > 620 ?
            <table className="w-[95%] table-auto mx-auto w-full text-xs">
            <thead className="bg-gray-600 !text-white hidden sm:table-header-group ">
              <tr>
                <th className="w-[10%] !text-white uppercase p-3">Fecha / Hora</th>
                <th className="w-[15%] !text-white uppercase">Tipo</th>
                <th className="w-[50%] !text-white uppercase">Detalle</th>
                <th className="w-[5%] !text-white uppercase">Entrada</th>
                <th className="w-[5%] !text-white uppercase">Salida</th>
                <th className="w-[5%] !text-white uppercase">Saldo</th>
                <th className="w-[20%] !text-white uppercase">Costo</th>
                {/* <th>Stock Anterior</th> */}
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={10}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {movements.length > 0 &&
                !isFetching &&
                movements?.map((movement) => {
                  return (
                    <tr key={movement.id} className={(movement.entry)? "text-green-600 ":"text-red-600 "}>
                      <td className="p-2">
                        {movement.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")
                          +" "+ movement.createdAt.substr(11, 5)}
                      </td>
                    
                      <td className="uppercase text-left">
                        {movement.movementype.description}
                      </td>
                      <td className="uppercase text-left">
                       {" "}
                        {/* {movement.sale ? movement.sale.stamped : movement.purchase.stamped } */}
                        {movement.sale
                          ? movement.sale.expeditionPoint
                          : movement.purchasecreditnote
                            ? movement.purchasecreditnote.expeditionPoint
                            : movement.salecreditnote
                              ? movement.salecreditnote.expeditionPoint
                              : movement.purchase
                                ? movement.purchase.expeditionPoint
                                : ""}{" "}
                        {movement.sale
                          ? movement.sale.number
                          : movement.purchasecreditnote
                            ? movement.purchasecreditnote.number
                            : movement.salecreditnote
                              ? movement.salecreditnote.number
                              : movement.purchase
                                ? movement.purchase.number
                                : ""}
                       
                        <b>
                          {movement.movementype.id == 10
                            ? " Ajuste de stock "
                            : " Proveedor "}
                          
                        </b>
                        {"  "}
                        {movement.sale ? movement.sale.client.name : ""}
                        {movement.purchase
                          ? movement.purchase.provider.name
                          : ""}
                        {movement.salecreditnote
                          ? movement.salecreditnote.client.name
                          : ""}
                        {movement.purchasecreditnote
                          ? movement.purchasecreditnote.provider.name
                          : ""}
                      </td>
                     
                      <td>{movement.entry}</td>
                      <td>{movement.exit}</td>
                      <td>{movement.balance}</td>
                      <td className="text-right">{numberWithCommas(movement.cost)}</td>
                      {/* <td>{movement.previouStock}</td> */}
                    </tr>
                  );
                })}
              {movements.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={10} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>
            : 
            <div className="w">aaaa</div>
          }
          

          <Pagination
            params={{ itemId, depositId, dateFrom, dateTo }}
            path="movimientos"
            page={page}
            pages={pages}
            total={total}
          />
        </div>
      </Layout>
    </>
  );
};

export default Movements;
